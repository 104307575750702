import { notifications } from '@mantine/notifications';
import { Button, Flex } from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import * as React from 'react';
import classes from './playlistDeletionNotification.module.css';
import { smallIconSize } from '../../../constants/constants';

export function playlistDeletedNotification(playlistName: string, callback: () => void) {
  const id = notifications.show({
    title: 'Playlist deleted',
    message: (
      <Flex align="center">
        {`Playlist '${playlistName}' has been deleted`}
        <Button
          ml="sm"
          size="xs"
          variant="light"
          classNames={{ root: classes.sweepButton }}
          leftSection={<IconArrowBackUp style={smallIconSize} />}
          onClick={() => {
            callback();
            notifications.hide(id);
          }}
        >
          Undo
        </Button>
      </Flex>
    ),
    radius: 'sm',
    color: 'blue',
    autoClose: 10000,
  });
}
