import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { StateCreator } from 'zustand/esm';
import { AuthSlice, authSliceInitialState, createAuthSlice } from './authStore';
import { createUserSlice, UserSlice, userSliceInitialState } from './userStore';
import { createPlaylistSlice, PlaylistSlice, playlistSliceInitialState } from './playlistStore';
import {
  createTrackActionSlice,
  TrackActionSlice,
  trackActionSliceInitialSate,
} from './trackActionStore';
import {
  createTargetPlaylistSlice,
  TargetPlaylistSlice,
  targetPlaylistSliceInitialState,
} from './targetPlaylistStore';
import {
  createTrackInteractionSlice,
  TrackInteractionSlice,
  trackInteractionSliceInitialSate,
} from './trackInteractionStore';
import { environment } from '../environments/environment';
import {
  createEditTargetPlaylistsSlice,
  EditTargetPlaylistSlice,
  editTargetPlaylistSliceInitialState,
} from './editTargetPlaylistsStore';
import {
  BatchTransferTrackSlice,
  batchTransferTrackSliceInitialState,
  createBatchTransferTrackSlice,
} from './batchTransferTrackStore';

interface ResetSlice {
  reset: () => void;
}

export type CombinedSlices = AuthSlice &
  UserSlice &
  PlaylistSlice &
  TrackActionSlice &
  TrackInteractionSlice &
  TargetPlaylistSlice &
  BatchTransferTrackSlice &
  EditTargetPlaylistSlice &
  ResetSlice;

const combinedInitialState = {
  ...userSliceInitialState,
  ...authSliceInitialState,
  ...playlistSliceInitialState,
  ...trackActionSliceInitialSate,
  ...trackInteractionSliceInitialSate,
  ...targetPlaylistSliceInitialState,
  ...batchTransferTrackSliceInitialState,
  ...editTargetPlaylistSliceInitialState,
};

const createResetSlice: StateCreator<CombinedSlices, [], [], ResetSlice> = (set) => ({
  reset: () => {
    set(combinedInitialState);
  },
});

// eslint-disable-next-line import/no-mutable-exports
export let useCombinedStore: UseBoundStore<StoreApi<CombinedSlices>>;

function combineSlices() {
  return (...a: unknown[]) => ({
    ...createAuthSlice(...(a as Parameters<typeof createAuthSlice>)),
    ...createUserSlice(...(a as Parameters<typeof createUserSlice>)),
    ...createPlaylistSlice(...(a as Parameters<typeof createPlaylistSlice>)),
    ...createTrackActionSlice(...(a as Parameters<typeof createTrackActionSlice>)),
    ...createTrackInteractionSlice(...(a as Parameters<typeof createTrackInteractionSlice>)),
    ...createTargetPlaylistSlice(...(a as Parameters<typeof createTargetPlaylistSlice>)),
    ...createBatchTransferTrackSlice(...(a as Parameters<typeof createBatchTransferTrackSlice>)),
    ...createEditTargetPlaylistsSlice(...(a as Parameters<typeof createEditTargetPlaylistsSlice>)),
    ...createResetSlice(...(a as Parameters<typeof createResetSlice>)),
  });
}

if (environment.production) {
  useCombinedStore = create<CombinedSlices>()(combineSlices());
} else {
  useCombinedStore = create<CombinedSlices>()(devtools(combineSlices()));
}
