import * as React from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { iconSize } from '../../../utils/iconSize';

export function ChevronIcon({ userMenuOpened }: Readonly<{ userMenuOpened: boolean }>) {
  return (
    <IconChevronDown
      style={{
        ...iconSize(12),
        transform: userMenuOpened ? 'rotate(-180deg)' : 'none',
        transition: 'transform 200ms ease',
      }}
      stroke={1.5}
    />
  );
}
