import React, { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext<string | null>(null);

interface NavigationProviderProps {
  children: ReactNode;
}

export function NavigationProvider({ children }: NavigationProviderProps) {
  const location = useLocation();
  const previousPathRef = useRef<string>(location.pathname);

  useEffect(() => {
    previousPathRef.current = location.pathname;
  }, [location]);

  return (
    <NavigationContext.Provider value={previousPathRef.current}>
      {children}
    </NavigationContext.Provider>
  );
}

export function usePreviousPath(): string | null {
  return useContext(NavigationContext);
}
