import * as React from 'react';
import { Dispatch } from 'react';
import { ScrollArea } from '@mantine/core';
import { useCombinedStore } from '../../../stores/combinedStore';
import { PlaylistRowSkeleton } from './playlistRow.skeleton';
import classes from '../templates/selectPlaylist.module.css';
import { TrackRow } from './trackRow';

export function TrackList({
  setScrolled,
}: Readonly<{
  setScrolled: Dispatch<React.SetStateAction<boolean>>;
}>) {
  const loadingTrack = useCombinedStore((state) => state.loadingTrack);
  const currentTrackList = useCombinedStore((state) => state.currentTrackList);

  if (loadingTrack) {
    return (
      <>
        <PlaylistRowSkeleton showCheckBox />
        <PlaylistRowSkeleton showCheckBox />
        <PlaylistRowSkeleton showCheckBox />
      </>
    );
  }

  return (
    <ScrollArea
      className={classes.scrollArea}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      {currentTrackList.map((track) => (
        <TrackRow key={track.uniqueId} track={track} />
      ))}
    </ScrollArea>
  );
}
