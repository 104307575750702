import { Card, Flex, Image, Skeleton } from '@mantine/core';
import * as React from 'react';
import classes from '../../shared/styles/transferTrack.module.css';
import { TrackInfoSkeleton } from './trackInfo.skeleton';
import emptyTrack from '../../../static/images/empty-playlist.png';

export function TrackCardSkeleton() {
  return (
    <Card className={classes.trackCardNeutralBackground}>
      <Flex className={classes.trackCardContents}>
        <Flex direction="column" align="center">
          <Skeleton visible className={classes.trackImageSkeleton}>
            <Image
              src={emptyTrack}
              width="100%"
              className={classes.trackImageInSkeletonContainer}
              alt="Empty track image placeholder"
            />
          </Skeleton>
          <div className={classes.trackControlPlaceholder} />
        </Flex>
        <TrackInfoSkeleton />
      </Flex>
    </Card>
  );
}
