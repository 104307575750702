import { rem, Skeleton, Table, Title } from '@mantine/core';
import * as React from 'react';
import classes from './trackInfo.module.css';
import { AudioFeatureSkeleton } from '../atoms/audioFeature.skeleton';

const width = rem(250);

export function TrackInfoSkeleton() {
  return (
    <div className={classes.trackInfoContainer}>
      <Skeleton visible w={width}>
        <Title order={3}>Track</Title>
      </Skeleton>
      <Skeleton visible className={classes.trackName} w={width}>
        <Title order={1}>Track</Title>
      </Skeleton>
      <Skeleton visible className={classes.artistName} w={width}>
        <Title order={2}>Artist</Title>
      </Skeleton>
      <Skeleton visible className={classes.albumName} w={width}>
        <Title order={3}>Album</Title>
      </Skeleton>
      <Skeleton visible className={classes.heading} w={width}>
        <Title order={4}>Label & date</Title>
      </Skeleton>
      <Skeleton visible className={classes.heading} w={width}>
        <Title order={4}>BPM & Key</Title>
      </Skeleton>
      <Table withRowBorders={false}>
        <Table.Tbody>
          <AudioFeatureSkeleton />
          <AudioFeatureSkeleton />
          <AudioFeatureSkeleton />
          <AudioFeatureSkeleton />
        </Table.Tbody>
      </Table>
    </div>
  );
}
