import { StateCreator } from 'zustand/esm';
import { SpotifyPaging, SpotifyPlaylist } from '../models/spotifyApi.interface';
import type { CombinedSlices } from './combinedStore';
import { initializeLikedSongs } from '../utils/initializeLikedSongs';
import { axiosService } from '../utils/axiosInstance';
import { SpotifyApiPaths, TransferActions } from '../constants/enums';
import { logError } from '../utils/logError';
import { filterUserEditablePlaylists, isPlaylistEditableByUser } from '../utils/userEditableUtils';
import { playlistDeletedNotification } from '../features/shared/molecules/playlistDeletedNotification';
import { actionUpdatedInfo } from '../constants/messages';

interface PlaylistState {
  userPlaylists?: SpotifyPlaylist[];
  userEditablePlaylists?: SpotifyPlaylist[];
  playlistsLoading: boolean;
  playlistsRefreshing: boolean;
  sourcePlaylist?: SpotifyPlaylist;
  numberOfPlaylists?: number;
  playlistAddRowMounted: boolean;
}

export interface PlaylistSlice extends PlaylistState {
  setSourcePlaylist: (selectedPlaylistId: string) => void;
  loadAllPlaylists: (refresh?: boolean) => Promise<void>;
  updateSnapshotIdOfSourcePlaylist: (snapshotId: string) => void;
  mountPlaylistAddRow: () => void;
  unmountPlaylistAddRow: () => void;
  createNewPlaylist: (newPlaylistName: string) => Promise<SpotifyPlaylist | undefined>;
  deletePlaylist: (playlistId: string) => Promise<void>;
  undoDeletePlaylist: (playlistId: string, includeAsPublic?: boolean) => Promise<void>;
}

export const playlistSliceInitialState: PlaylistState = {
  userPlaylists: undefined,
  userEditablePlaylists: undefined,
  playlistsLoading: false,
  playlistsRefreshing: false,
  sourcePlaylist: undefined,
  numberOfPlaylists: undefined,
  playlistAddRowMounted: false,
};

export const limit = 50;

export const createPlaylistSlice: StateCreator<CombinedSlices, [], [], PlaylistSlice> = (
  set,
  get
) => ({
  ...playlistSliceInitialState,

  loadAllPlaylists: async (refresh = false) => {
    const { allEditTargetPlaylistSelected, spotifyToken } = get();

    let { userProfile } = get();
    let userId = userProfile?.id;

    if (!userProfile || !userId) {
      if (spotifyToken) {
        await get().getCurrentUserProfile();
      }
      userProfile = get().userProfile;
      userId = userProfile?.id;

      if (!userProfile || !userId) {
        logError('UserId not found when loading playlists');
        return;
      }
    }

    if (refresh) {
      set({ playlistsRefreshing: true });
    } else {
      set({ playlistsLoading: true });
    }

    const axiosInstance = axiosService.getInstance();
    const { data } = await axiosInstance.get<SpotifyPaging<SpotifyPlaylist>>(
      `${SpotifyApiPaths.Me}${SpotifyApiPaths.Playlists}?limit=${limit}`
    );

    const numberOfPlaylists = data.total;

    // Load remaining playlists
    const promises = Array.from({ length: Math.ceil(numberOfPlaylists / limit) }, (_, index) => {
      const offset = index * limit;
      const url = `${SpotifyApiPaths.Me}${SpotifyApiPaths.Playlists}?offset=${offset}&limit=${limit}`;
      return axiosInstance.get<SpotifyPaging<SpotifyPlaylist>>(url);
    });

    const responses = await Promise.all(promises);

    let allPlaylists = [...data.items];

    responses.forEach((response) => {
      if (response.data.items.length > 0) {
        allPlaylists = [...allPlaylists, ...response.data.items];
      }
    });

    // filter allPlaylist duplicates, this shouldn't be needed but might be a bug in spotify's api?
    const userPlaylists: SpotifyPlaylist[] = [];
    const playlistIdSet = new Set<string>();

    allPlaylists.forEach((playlist) => {
      if (!playlistIdSet.has(playlist.id)) {
        playlistIdSet.add(playlist.id);
        userPlaylists.push(playlist);
      }
    });

    if (get().numberOfLikedSongs === undefined) {
      await get().loadNumberOfLikedSongs();
    }

    const likedSongsPlaylist = initializeLikedSongs(userProfile, get().numberOfLikedSongs);

    // Insert Liked Songs at the beginning of the array
    userPlaylists.unshift(likedSongsPlaylist);

    set({
      userPlaylists,
      userEditablePlaylists: filterUserEditablePlaylists(userPlaylists, userId),
      numberOfPlaylists: numberOfPlaylists + 1, // +1 for Liked Songs
      playlistsLoading: false,
      playlistsRefreshing: false,
    });

    // add all playlists to target selection on initial load or when all playlists were selected
    if (!refresh || allEditTargetPlaylistSelected) {
      get().setAllEditablePlaylistsAsInTargetPlaylistSelection();
    }

    get().updateTargetPlaylistSelectionCheckboxState();
  },

  setSourcePlaylist(selectedPlaylistId: string) {
    const { userPlaylists, userProfile, transferAction } = get();

    const sourcePlaylist = userPlaylists?.find((playlist) => playlist.id === selectedPlaylistId);

    if (!sourcePlaylist) {
      logError('Playlist id of clicked playlist not in list of playlists');
      return;
    }

    if (!userProfile) {
      logError('User profile not loaded while setting source playlist');
      return;
    }

    const userEditable = isPlaylistEditableByUser(sourcePlaylist, userProfile.id);

    if (transferAction === TransferActions.Move && !userEditable) {
      get().setTransferAction(TransferActions.Copy);
      get().informUser(actionUpdatedInfo(sourcePlaylist.name));
    }

    set({
      loadingTrack: true,
      sourcePlaylist: {
        ...sourcePlaylist,
        userEditable,
      },
      sourcePlaylistEmpty: sourcePlaylist.tracks.total === 0,
    });

    get().loadAllTracksOfCurrentPlaylist();
    get().deselectAllTracks();
  },

  updateSnapshotIdOfSourcePlaylist(snapshotId: string) {
    set((state) => {
      if (!state.sourcePlaylist) {
        logError('No source playlist while updating its snapshot_id');
        return state; // Return the current state unchanged
      }

      return {
        ...state,
        sourcePlaylist: {
          ...state.sourcePlaylist,
          snapshot_id: snapshotId,
        },
      };
    });
  },

  mountPlaylistAddRow() {
    set({ playlistAddRowMounted: true });
  },

  unmountPlaylistAddRow() {
    set({ playlistAddRowMounted: false });
  },

  async createNewPlaylist(newPlaylistName: string): Promise<SpotifyPlaylist | undefined> {
    const { userProfile } = get();

    if (!userProfile) {
      logError('User profile not loaded while creating new playlist');
      return undefined;
    }

    const axiosInstance = axiosService.getInstance();
    const { data } = await axiosInstance.post<SpotifyPlaylist>(
      `${SpotifyApiPaths.Me}${SpotifyApiPaths.Playlists}`,
      { name: newPlaylistName }
    );

    get().loadAllPlaylists(true);

    set({ playlistAddRowMounted: false });

    return data;
  },

  async deletePlaylist(playlistId: string) {
    const axiosInstance = axiosService.getInstance();
    await axiosInstance.delete(`${SpotifyApiPaths.Playlists}/${playlistId}/followers`);

    get().removeFromTargetPlaylistSelection(playlistId);
    get().removeFromSelectedTargetPlaylistIds(playlistId);

    const { userPlaylists } = get();

    const sourcePlaylist = userPlaylists?.find((playlist) => playlist.id === playlistId);

    playlistDeletedNotification(sourcePlaylist?.name ?? '', () =>
      get().undoDeletePlaylist(playlistId, sourcePlaylist?.public)
    );

    get().loadAllPlaylists(true);
  },

  async undoDeletePlaylist(playlistId: string, includeAsPublic = true) {
    const axiosInstance = axiosService.getInstance();
    await axiosInstance.put(`${SpotifyApiPaths.Playlists}/${playlistId}/followers`, {
      public: includeAsPublic,
    });
    get().loadAllPlaylists(true);
  },
});
