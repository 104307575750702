import * as React from 'react';
import { Button, Text } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from '../../../constants/enums';
import { SelectPlaylist } from '../../selectPlaylistTemplate/templates/selectPlaylist';
import { useCombinedStore } from '../../../stores/combinedStore';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';
import { smallIconSize } from '../../../constants/constants';

export function SelectTargetPlaylists() {
  const playlists = useCombinedStore((state) => state.userEditablePlaylists);
  const selectedTargetPlaylistIds = useCombinedStore((state) => state.selectedTargetPlaylistIds);
  const targetPlaylistSelection = useCombinedStore((state) => state.targetPlaylistSelection);
  const anyTargetSelected = useCombinedStore((state) => state.anyTargetSelected);
  const allTargetsSelected = useCombinedStore((state) => state.allTargetsSelected);
  const allEditTargetPlaylistSelected = useCombinedStore(
    (state) => state.allEditTargetPlaylistSelected
  );
  const clickedSelectAllTarget = useCombinedStore((state) => state.clickedSelectAllTarget);
  const toggleTargetCheckbox = useCombinedStore((state) => state.toggleTargetCheckbox);
  const toggleTargetCurrentPlaylist = useCombinedStore(
    (state) => state.toggleTargetCurrentPlaylist
  );
  const transferAction = useCombinedStore((state) => state.transferAction);

  const navigate = useNavigate();

  const noTargetSelected = !anyTargetSelected;

  const multiSelect: MultiSelectSettings = {
    combinedChecked: clickedSelectAllTarget || allTargetsSelected, // or only anyTargetSelected or only all selected; difference between green or black indeterminate dash
    combinedIndeterminate: !(noTargetSelected || allTargetsSelected),
    combinedOnChange: () => toggleTargetCheckbox(),
    isSelectedCheck: (playlist) => selectedTargetPlaylistIds.has(playlist.id),
  };

  return (
    <SelectPlaylist
      heading={`${transferAction} track to:`}
      playlists={playlists?.filter((playlist) => targetPlaylistSelection.has(playlist.id))}
      onPlaylistClick={toggleTargetCurrentPlaylist}
      multiSelect={multiSelect}
      headerRowItems={[
        <Text fw={700} key="title">
          {allEditTargetPlaylistSelected ? 'All playlists' : 'Target Playlist Selection'}
        </Text>,
        <Button
          key="edit"
          onClick={() => navigate(AppPaths.EditTargetPlaylists)}
          size="xs"
          leftSection={<IconPencil style={smallIconSize} />}
          aria-label="Edit target playlist selection"
        >
          Edit
        </Button>,
      ]}
    />
  );
}
