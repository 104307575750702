import * as Sentry from '@sentry/react';
import { notifications } from '@mantine/notifications';
import { environment } from '../environments/environment';

export function logError(message: string, error?: Error) {
  if (!environment.production) {
    // eslint-disable-next-line no-console
    console.error(message);
    // eslint-disable-next-line no-console
    console.error(error?.message);
    notifications.show({
      title: 'DEBUG Error',
      message,
      radius: 'sm',
      color: 'red',
      autoClose: 10000,
    });
  } else {
    Sentry.captureException(error ?? new Error(message));
  }
}

export function logDebug(message: string) {
  if (!environment.production) {
    const messageWithTimeStamp = `${new Date().toLocaleString('nl-NL')}: ${message}`;

    // eslint-disable-next-line no-console
    console.info(messageWithTimeStamp);

    notifications.show({
      title: 'DEBUG Info',
      message: messageWithTimeStamp,
      radius: 'sm',
      color: 'blue',
      autoClose: 5000,
    });
  }
}
