import { Center, Progress, Table } from '@mantine/core';
import * as React from 'react';
import { AudioFeatureItem } from '../../../models/audioFeatureItem';
import classes from './audioFeature.module.css';
import { xsIconSize } from '../../../constants/constants';

export function AudioFeature({
  label,
  value,
  color,
  fromIcon: FromIcon,
  toIcon: ToIcon,
}: AudioFeatureItem) {
  return (
    <Table.Tr>
      <Table.Td className={classes.pl0}>{`${label}:`}</Table.Td>
      <Table.Td className={classes.pr0}>{(value * 100).toFixed(0)}</Table.Td>
      <Table.Td className={classes.pl0}>%</Table.Td>
      <Table.Td>
        <Center>
          <FromIcon style={xsIconSize} />
        </Center>
      </Table.Td>
      <Table.Td className={classes.progressCell}>
        <Progress radius="md" size="lg" value={value * 100} color={color} />
      </Table.Td>
      <Table.Td className={classes.pr10}>
        <Center>
          <ToIcon style={xsIconSize} />
        </Center>
      </Table.Td>
    </Table.Tr>
  );
}
