import React, { useState } from 'react';
import {
  ActionIcon,
  Avatar,
  Button,
  Checkbox,
  Flex,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { IconPlaylist, IconPlaylistAdd, IconX } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import classes from './playlistRow.module.css';
import { useCombinedStore } from '../../../stores/combinedStore';
import {
  defaultGap,
  playlistIconSize,
  playlistRowImageRadius,
  playlistRowImageSize,
  smallIconSize,
} from '../../../constants/constants';

export function PlaylistAddRow({ style }: Readonly<{ style?: React.CSSProperties }>) {
  const createNewPlaylist = useCombinedStore((state) => state.createNewPlaylist);
  const unmountPlaylistAddRow = useCombinedStore((state) => state.unmountPlaylistAddRow);

  const [newPlaylistName, setNewPlaylistName] = useState('');

  const theme = useMantineTheme();
  const mediaQuery = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  return (
    <Flex
      className={classes.tableRowOnlyPadding}
      direction="row"
      align="center"
      gap={defaultGap}
      style={style}
    >
      <Checkbox radius="xl" checked={false} disabled />
      <Avatar
        size={playlistRowImageSize}
        radius={playlistRowImageRadius}
        alt="New playlist cover image"
      >
        <IconPlaylist style={playlistIconSize} />
      </Avatar>
      <TextInput
        radius="sm"
        variant="filled"
        withAsterisk
        placeholder="New playlist name"
        onChange={(event) => setNewPlaylistName(event.currentTarget.value)}
        className={classes.fullWidthTextInput}
      />
      {mediaQuery ? (
        <>
          <Button
            onClick={() => createNewPlaylist(newPlaylistName)}
            size="sm"
            leftSection={<IconPlaylistAdd style={smallIconSize} />}
            disabled={newPlaylistName === ''}
            aria-label="Create new playlist"
          >
            Create
          </Button>
          <Button
            onClick={() => unmountPlaylistAddRow()}
            size="sm"
            variant="light"
            leftSection={<IconX style={smallIconSize} />}
            aria-label="Cancel creating new playlist"
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <ActionIcon
            onClick={() => createNewPlaylist(newPlaylistName)}
            size="lg"
            disabled={newPlaylistName === ''}
            aria-label="Create new playlist"
          >
            <IconPlaylistAdd style={smallIconSize} />
          </ActionIcon>
          <ActionIcon
            onClick={() => unmountPlaylistAddRow()}
            size="lg"
            variant="light"
            aria-label="Cancel creating new playlist"
          >
            <IconX style={smallIconSize} />
          </ActionIcon>
        </>
      )}
    </Flex>
  );
}
