import { Avatar, Checkbox, Flex, Text } from '@mantine/core';
import * as React from 'react';
import { memo, useRef } from 'react';
import { useInView } from 'framer-motion';
import { IconBrandApplePodcast, IconFileMusic, IconPlaylist } from '@tabler/icons-react';
import { SpotifyTrackItem } from '../../../models/spotifyApi.interface';
import classes from './playlistRow.module.css';
import {
  defaultGap,
  inViewMargin,
  playlistIconSize,
  playlistRowImageRadius,
  playlistRowImageSize,
  singleLineLineClamp,
  smallGap,
  xsIconSize,
} from '../../../constants/constants';
import { useCombinedStore } from '../../../stores/combinedStore';

export const TrackRow = memo(({ track }: { track: SpotifyTrackItem }) => {
  const toggleCurrentTrackSelected = useCombinedStore((state) => state.toggleCurrentTrackSelected);
  const selectedTrackIds = useCombinedStore((state) => state.selectedTrackIds);

  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: inViewMargin,
  });

  const trackImages = track.track.album.images;
  const trackImage = track.albumImage || trackImages?.[trackImages.length - 1]?.url || undefined;

  let artistString = track.track.artists.map((artist) => artist.name).join(', ');

  if (artistString === '' && track.track.type === 'episode') {
    artistString = 'Podcast episode';
  }

  const disabled = track.track.is_local || undefined;

  function handleTrackClick() {
    return () => toggleCurrentTrackSelected(track.uniqueId);
  }

  return (
    <Flex
      ref={ref}
      onClick={disabled ? () => {} : handleTrackClick()}
      className={classes.tableRow}
      aria-disabled={disabled}
      tabIndex={0}
      role="button"
      // onKeyUp={disabled ? () => {} : handlePlaylistRowKey}
      direction="row"
      align="center"
      justify="space-between"
    >
      {isInView ? (
        <Flex direction="row" align="center" gap={defaultGap}>
          <Checkbox
            radius="xl"
            disabled={disabled}
            checked={selectedTrackIds.has(track.uniqueId)}
            onChange={handleTrackClick()}
            onClick={(event) => event.stopPropagation()}
          />
          <Avatar
            size={playlistRowImageSize}
            src={trackImage}
            radius={playlistRowImageRadius}
            alt={`Playlist cover image of ${track.track.name} by ${artistString}`}
          >
            <IconPlaylist style={playlistIconSize} />
          </Avatar>
          <Flex direction="row" align="center">
            <Flex direction="column">
              <Text lineClamp={singleLineLineClamp} className={classes.playlistName}>
                {track.track.name}
              </Text>
              <Flex gap={smallGap} align="center">
                <Text lineClamp={singleLineLineClamp} size="sm" className={classes.subText}>
                  {artistString}
                </Text>
                {track.track.type === 'episode' ? (
                  <IconBrandApplePodcast style={xsIconSize} className={classes.subText} />
                ) : null}
                {track.track.is_local ? (
                  <IconFileMusic style={xsIconSize} className={classes.subText} />
                ) : null}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <div className={classes.emptyRow} />
      )}
    </Flex>
  );
});

TrackRow.displayName = 'TrackRow';
