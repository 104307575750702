import { Group, Menu, UnstyledButton } from '@mantine/core';
import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
import classes from './userMenu.module.css';
import { LogOutMenuItem } from '../atoms/logOutMenuItem';
import { useCombinedStore } from '../../../stores/combinedStore';
import { UserMenuButton } from '../molecules/userMenuButton';

export function UserMenu() {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const profile = useCombinedStore((state) => state.userProfile);
  const profileLoading = useCombinedStore((state) => state.userProfileLoading);

  if (!profile && !profileLoading) {
    return null;
  }

  return (
    <Group justify="space-between" className={classes.menu}>
      <Menu
        position="bottom"
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
        withinPortal
        radius="md"
      >
        <Menu.Target>
          <UnstyledButton className={clsx(classes.user, { [classes.userActive]: userMenuOpened })}>
            <UserMenuButton userMenuOpened={userMenuOpened} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <LogOutMenuItem />
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
