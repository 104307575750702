import { SpotifyTrackItem } from '../models/spotifyApi.interface';

export function filterTracksByUniqueIds(
  currentTrackList: SpotifyTrackItem[],
  uniqueIds: Set<string>
) {
  return currentTrackList
    .map((track, index) => ({ track, index }))
    .filter(({ track }) => uniqueIds.has(track.uniqueId));
}

export function filterOutTracksByUniqueIds(
  currentTrackList: SpotifyTrackItem[],
  uniqueIds: Set<string>
) {
  return currentTrackList.filter((track) => !uniqueIds.has(track.uniqueId));
}
