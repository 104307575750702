import { Flex, Title } from '@mantine/core';
import * as React from 'react';
import { SelectTargetPlaylists } from '../../transferTracks/organisms/selectTargetPlaylists';
import classes from './batchTransfer.module.css';
import { TransferActionButtons } from '../../transferTracks/organisms/transferActionButtons';
import { SelectTrackList } from '../../selectPlaylistTemplate/templates/selectTrackList';
import { useCombinedStore } from '../../../stores/combinedStore';
import { NoSourcePlaylistInfo } from '../../shared/molecules/NoSourcePlaylistInfo';

export function BatchTransfer() {
  const selectedSourcePlaylist = useCombinedStore((state) => state.sourcePlaylist);
  const sourcePlaylistEmpty = useCombinedStore((state) => state.sourcePlaylistEmpty);

  if (!selectedSourcePlaylist || sourcePlaylistEmpty) {
    return <NoSourcePlaylistInfo title="Batch Transfer Tracks" />;
  }

  return (
    <Flex direction="column" className={classes.container}>
      <Title order={3}>Batch Transfer Tracks from {selectedSourcePlaylist.name}</Title>
      <TransferActionButtons batchTransfer />
      <Flex direction="row" className={classes.listContainer}>
        <Flex className={classes.listWrapper}>
          <SelectTrackList />
        </Flex>
        <Flex className={classes.listWrapper}>
          <SelectTargetPlaylists />
        </Flex>
      </Flex>
    </Flex>
  );
}
