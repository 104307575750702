export const environment = {
  // @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'
  production: import.meta.env.VITE_ENVIRONMENT !== 'local',
  spotifyStorageKey: 'spotify_token',
  spotifyApiBaseUrl: 'https://api.spotify.com/v1',
  // @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'
  dsn: import.meta.env.VITE_SENTRY_DSN,
  // @ts-expect-error: Property 'env' does not exist on type 'ImportMeta'
  sentryEnvironment: import.meta.env.VITE_ENVIRONMENT,
};
