import { rem } from '@mantine/core';
import { iconSize } from '../utils/iconSize';

export const likedSongsId = 'liked songs';

export const defaultGap = rem(16);
export const smallGap = rem(6);

export const playlistIconSize = iconSize(22);
export const smallIconSize = iconSize(18);
export const xsIconSize = iconSize(14);

export const xsIconPlaceholderHeight = rem(14);

export const playlistRowImageSize = rem(44);
export const playlistRowImageRadius = 0; // was: 'sm'

export const controlButtonSize = 55;
export const controlButtonIconSize = controlButtonSize / 2.25;
export const controlButtonRingProgressThickness = controlButtonSize / 10;

export const singleLineLineClamp = 1;

export const playlistRowPadding = 8;
export const differenceBetweenIconAndCheckbox = 20 - 18;
export const warningFlexPadding = rem(playlistRowPadding + differenceBetweenIconAndCheckbox / 2);

export const inViewMargin = '400px 0px';
