import * as React from 'react';
import { Dispatch, useEffect, useRef } from 'react';
import { ScrollArea } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { PlaylistRowSkeleton } from './playlistRow.skeleton';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from '../templates/selectPlaylist.module.css';
import { PlaylistAddRow } from './playlistAddRow';
import { PlaylistRow } from './playlistRow';
import { SpotifyPlaylist } from '../../../models/spotifyApi.interface';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';

const duration = 0.5;
const variants = {
  enterAddRow: { opacity: 0, y: -20, height: 0, transition: { duration } },
  enterRow: { opacity: 0, y: -20, height: 0.5, transition: { duration } },
  center: { opacity: 1, y: 0, height: 'auto', transition: { duration } },
  exit: { opacity: 0, y: 20, height: 0.5, transition: { duration } },
  exitDelete: { opacity: 0, x: -200, height: 0, transition: { duration } },
};

interface PlaylistTableBodyProps {
  playlists?: SpotifyPlaylist[];
  onPlaylistClick: (playlistId: string) => void;
  multiSelect?: MultiSelectSettings;
  setScrolled: Dispatch<React.SetStateAction<boolean>>;
}

export function PlaylistList({
  playlists,
  onPlaylistClick,
  multiSelect,
  setScrolled,
}: Readonly<PlaylistTableBodyProps>) {
  const scrollAreaViewport = useRef<HTMLDivElement>(null);

  const scrollToTop = () => scrollAreaViewport.current?.scrollTo({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    if (multiSelect?.playlistAddRowMounted) {
      scrollToTop();
    }
  }, [multiSelect]);

  const isPlaylistsLoading = useCombinedStore((state) => state.playlistsLoading);

  if ((!playlists || playlists?.length === 0) && isPlaylistsLoading) {
    return (
      <>
        <PlaylistRowSkeleton showCheckBox={multiSelect !== undefined} />
        <PlaylistRowSkeleton showCheckBox={multiSelect !== undefined} />
        <PlaylistRowSkeleton showCheckBox={multiSelect !== undefined} />
      </>
    );
  }

  if (!multiSelect) {
    return (
      <ScrollArea
        className={classes.scrollArea}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        {playlists?.map((playlist: SpotifyPlaylist, index: number) => (
          <PlaylistRow
            // eslint-disable-next-line react/no-array-index-key
            key={`${playlist.id}-${index}`} // this ensures a rerender after adding or removing a playlist
            playlist={playlist}
            onPlaylistClick={() => onPlaylistClick(playlist.id)}
            showCheckBox={multiSelect !== undefined}
            disabled={playlist.tracks.total === 0}
          />
        ))}
        {isPlaylistsLoading && <PlaylistRowSkeleton showCheckBox={multiSelect !== undefined} />}
      </ScrollArea>
    );
  }

  return (
    <ScrollArea
      className={classes.scrollArea}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      viewportRef={scrollAreaViewport}
    >
      <AnimatePresence initial={false} custom="sync">
        {multiSelect?.playlistAddRowMounted ? (
          <motion.div variants={variants} initial="enterAddRow" animate="center" exit="exit">
            <PlaylistAddRow />
          </motion.div>
        ) : null}
        {playlists?.map((playlist: SpotifyPlaylist, index: number) => (
          <motion.div
            key={`${playlist.id}`}
            variants={variants}
            initial="enterRow"
            animate="center"
            exit="exitDelete"
          >
            <PlaylistRow
              // eslint-disable-next-line react/no-array-index-key
              key={`${playlist.id}-${index}`} // this ensures a rerender after adding or removing a playlist
              playlist={playlist}
              onPlaylistClick={() => onPlaylistClick(playlist.id)}
              showCheckBox={multiSelect !== undefined}
              disabled={!multiSelect && playlist.tracks.total === 0}
              isSelectedCheck={multiSelect?.isSelectedCheck}
              onDelete={multiSelect?.onDelete}
            />
          </motion.div>
        ))}
      </AnimatePresence>
      {isPlaylistsLoading ? <PlaylistRowSkeleton showCheckBox /> : null}
    </ScrollArea>
  );
}
