import { StateCreator } from 'zustand/esm';
import type { CombinedSlices } from './combinedStore';
import { axiosService } from '../utils/axiosInstance';
import {
  DialogResults,
  SpotifyApiPaths,
  TrackDirections,
  TransferActions,
} from '../constants/enums';
import { logError } from '../utils/logError';
import { UndoBatchAction, UndoSingleAction } from '../models/undoAction.interface';
import {
  filterOutTracksByUniqueIds,
  filterTracksByUniqueIds,
} from '../utils/filterTracksByUniqueIds';
import { batchRemoveNotification } from '../features/shared/molecules/batchRemoveNotification';

interface TrackInteractionState {
  likingTrackInProgress: boolean;
  undoStack: UndoSingleAction[];
  isDeleteAnimationActive: boolean;
  animationPromise: Promise<void> | undefined;
  likeOrderModalIsOpen: boolean;
  likeOrderModalDontShowAgain: boolean;
  closeLikeOrderModal: undefined | ((result: DialogResults) => void);
  alreadyConfirmedForMoving: boolean;
  sourcePlaylistEmpty: boolean;
}

export interface TrackInteractionSlice extends TrackInteractionState {
  toggleLikedSong: () => Promise<void>;
  removeTracksFromSourcePlaylist: (uniqueIds: Set<string>) => Promise<void>;
  removeTrackFromSourcePlaylist: () => Promise<void>;
  removeCurrentTrackFromTrackList: () => void;
  storeRemoveAction: () => void;
  undoRemove: () => Promise<void>;
  undoBatchRemove: (lastRemoveAction: UndoBatchAction) => Promise<void>;
  triggerDeleteAnimation: () => Promise<void>; // Return a promise
  openLikeOrderModal: () => Promise<DialogResults>;
  setLikeOrderModalDontShowAgainStatus: (result: boolean) => void;
  handleTransferAction: (trackDirection: TrackDirections) => Promise<void>;
}

export const trackInteractionSliceInitialSate: TrackInteractionState = {
  likingTrackInProgress: false,
  undoStack: [],
  isDeleteAnimationActive: false,
  animationPromise: undefined,
  likeOrderModalIsOpen: false,
  likeOrderModalDontShowAgain: false,
  closeLikeOrderModal: undefined,
  alreadyConfirmedForMoving: false,
  sourcePlaylistEmpty: false,
};

export const createTrackInteractionSlice: StateCreator<
  CombinedSlices,
  [],
  [],
  TrackInteractionSlice
> = (set, get) => ({
  ...trackInteractionSliceInitialSate,

  toggleLikedSong: async () => {
    const { currentTrack, sourcePlaylist } = get();

    if (!currentTrack) {
      logError('Missing current track while toggling liked song');
      return;
    }

    // Disable the button to prevent rapid clicks.
    set({ likingTrackInProgress: true });

    const axiosInstance = axiosService.getInstance();

    // Toggle the liked status.
    if (currentTrack.liked) {
      const dialogResult = await get().likeOrderModalResult();

      if (dialogResult === DialogResults.Confirmed) {
        // If it's liked, perform the "unlike" action (DELETE).
        await axiosInstance.delete(`${SpotifyApiPaths.MyTracks}?ids=${currentTrack.track.id}`);

        // If current playlist is liked songs, also remove
        if (sourcePlaylist?.isLikedSongs) {
          if (get().animationPromise === undefined) {
            set({ animationPromise: get().triggerDeleteAnimation() });
          }

          get().storeRemoveAction();

          get().removeCurrentTrackFromTrackList();
        }
      } else {
        set({ likingTrackInProgress: false });
        return;
      }
    } else {
      // If it's not liked, perform the "like" action (PUT).
      await axiosInstance.put(`${SpotifyApiPaths.MyTracks}?ids=${currentTrack.track.id}`, null);
    }

    // Update the liked state in the currentTrack object.
    set({
      likingTrackInProgress: false,
      currentTrack: { ...currentTrack, liked: !currentTrack.liked },
    });
  },

  removeTracksFromSourcePlaylist: async (uniqueIds: Set<string>) => {
    const { sourcePlaylist, currentTrackList } = get();

    const tracksToRemove = filterTracksByUniqueIds(currentTrackList, uniqueIds);

    if (!sourcePlaylist) {
      logError('Missing source playlist while removing tracks from source playlists');
      return;
    }

    const axiosInstance = axiosService.getInstance();

    if (sourcePlaylist.isLikedSongs) {
      // unlike liked songs
      const dialogResult = await get().likeOrderModalResult();

      if (dialogResult === DialogResults.Confirmed) {
        // If it's liked, perform the "unlike" action (DELETE).
        await axiosInstance.delete(
          `${SpotifyApiPaths.MyTracks}?ids=${tracksToRemove.map((tracks) => tracks.track.track.id).join(',')}`
        );
      }
    } else {
      const spotifyFixedTheirShit = false;
      let data;

      if (spotifyFixedTheirShit) {
        // if they never end up fixing their shit, we can also remove the entire uniqueId implementation

        const groupedTracks = tracksToRemove.reduce(
          (acc, track) => {
            const { uri } = track.track.track;
            if (!acc[uri]) {
              acc[uri] = [];
            }
            acc[uri].push(track.index);
            return acc;
          },
          {} as Record<string, number[]>
        );

        // Then, create the payload using the grouped tracks
        const tracks = Object.entries(groupedTracks).map(([uri, positions]) => ({
          uri,
          positions,
        }));

        data = {
          tracks,
          // snapshot_id: sourcePlaylist.snapshot_id, // testing with this off, seems to reduce flakeyness of this endpoint
        };
      } else {
        const positions = tracksToRemove.map((track) => track.index);

        data = { positions };
      }

      const result = await axiosInstance.delete<{ snapshot_id: string }>(
        `${SpotifyApiPaths.Playlists}/${sourcePlaylist.id}/tracks`,
        { data }
      );

      get().updateSnapshotIdOfSourcePlaylist(result.data.snapshot_id);
    }

    const undoAction: UndoBatchAction = {
      removedFromPlaylist: sourcePlaylist,
      removedTracks: tracksToRemove.map((track) => track.track),
      batch: true,
    };

    batchRemoveNotification(sourcePlaylist.name, uniqueIds.size, () =>
      get().undoBatchRemove(undoAction)
    );

    // Update the state with the new track list and index
    const newCurrentTrackList = filterOutTracksByUniqueIds(currentTrackList, uniqueIds);
    set({
      currentTrackList: newCurrentTrackList,
      sourcePlaylistEmpty: newCurrentTrackList.length === 0,
    });
  },

  removeTrackFromSourcePlaylist: async () => {
    // store track and index in store
    const { currentTrack, currentTrackIndex, sourcePlaylist } = get();

    if (!sourcePlaylist || !currentTrack) {
      if (!currentTrack) {
        logError('Missing current track while removing song from source playlists');
      }
      if (!sourcePlaylist) {
        logError('Missing source playlist while removing song from source playlists');
      }
      return;
    }

    set({ animationPromise: get().triggerDeleteAnimation() });

    if (sourcePlaylist.isLikedSongs && currentTrack.liked) {
      get().toggleLikedSong();
    } else if (!sourcePlaylist.isLikedSongs) {
      get().storeRemoveAction();

      const spotifyFixedTheirShit = false;
      let data;

      if (spotifyFixedTheirShit) {
        data = {
          tracks: [
            {
              uri: currentTrack.track.uri,
              positions: [currentTrackIndex], // ignored on > one item :( ?...
            },
          ],
          // snapshot_id: sourcePlaylist.snapshot_id, // testing with this off, seems to reduce flakyness of this endpoint
        };
      } else {
        data = { positions: [currentTrackIndex] };
      }

      // remove track from current playlist (via spotify api), but also in memory
      const result = await axiosService.getInstance().delete<{
        snapshot_id: string;
      }>(`${SpotifyApiPaths.Playlists}/${sourcePlaylist.id}/tracks`, { data });

      get().updateSnapshotIdOfSourcePlaylist(result.data.snapshot_id);

      get().removeCurrentTrackFromTrackList();
    } else {
      logError('We are in liked songs, deleting a track, but the current track is not liked');
    }
  },

  removeCurrentTrackFromTrackList: () => {
    const { currentTrackIndex, currentTrackList } = get();

    // Check if the current track list is empty or the index is out of range
    if (
      currentTrackList.length === 0 ||
      currentTrackIndex < 0 ||
      currentTrackIndex >= currentTrackList.length
    ) {
      logError('Invalid track index or empty track list');
      return;
    }

    // Create a new track list without the current track
    const newCurrentTrackList = [
      ...currentTrackList.slice(0, currentTrackIndex),
      ...currentTrackList.slice(currentTrackIndex + 1),
    ];

    // Determine the new track index
    const newCurrentTrackIndex = currentTrackIndex - 1; // loadNextTrack will add 1 to the index

    // Update the state with the new track list and index
    set({
      currentTrackList: newCurrentTrackList,
      currentTrackIndex: newCurrentTrackIndex,
      sourcePlaylistEmpty: newCurrentTrackList.length === 0,
    });

    // Load the next track if the list is not empty
    if (newCurrentTrackList.length > 0) {
      get().loadNextTrack();
    } else {
      set({ currentTrack: undefined, currentTrackIndex: -1 });
    }
  },

  storeRemoveAction: () => {
    const { currentTrack, currentTrackIndex, sourcePlaylist } = get();

    if (!sourcePlaylist || !currentTrack) {
      if (!currentTrack) {
        logError('Missing current track while storing it before removal');
      }
      if (!sourcePlaylist) {
        logError('Missing source playlist while storing it before removal');
      }
      return;
    }

    set((state) => ({
      ...state,
      undoStack: [
        ...state.undoStack,
        {
          removedFromPlaylist: sourcePlaylist,
          removedTrack: currentTrack,
          removedTrackIndex: currentTrackIndex,
          batch: false,
        },
      ],
    }));
  },

  undoRemove: async () => {
    const { undoStack, sourcePlaylist, sourcePlaylistEmpty } = get();

    if (undoStack.length === 0) {
      logError('Undoing on empty undo stack');
      return;
    }

    // Retrieve the last action immutably and prepare a new undo stack
    const lastRemoveAction = undoStack[undoStack.length - 1];
    const newUndoStack = undoStack.slice(0, -1);

    const axiosInstance = axiosService.getInstance();

    // API call and logic based on the type of playlist
    if (lastRemoveAction.removedFromPlaylist.isLikedSongs) {
      // like song via API
      await axiosInstance.put(
        `${SpotifyApiPaths.MyTracks}?ids=${lastRemoveAction.removedTrack.track.id}`,
        null
      );
    } else {
      const result = await axiosInstance.post<{ snapshot_id: string }>(
        `${SpotifyApiPaths.Playlists}/${lastRemoveAction.removedFromPlaylist.id}/tracks`,
        {
          uris: [lastRemoveAction.removedTrack.track.uri],
          position: lastRemoveAction.removedTrackIndex,
        }
      );

      // Update snapshot ID
      get().updateSnapshotIdOfSourcePlaylist(result.data.snapshot_id);
    }

    // Update the state atomically with functional updates
    set((state) => {
      const newCurrentTrackList = [...state.currentTrackList];
      let newCurrentTrackIndex = state.currentTrackIndex;

      // Adjust the current track list and index based on the last removed action
      if (lastRemoveAction.removedFromPlaylist.id === sourcePlaylist?.id) {
        const insertIndex = lastRemoveAction.removedFromPlaylist.isLikedSongs
          ? 0
          : lastRemoveAction.removedTrackIndex;
        newCurrentTrackList.splice(insertIndex, 0, lastRemoveAction.removedTrack);

        if (newCurrentTrackIndex >= insertIndex) {
          newCurrentTrackIndex += 1;
        }
      }

      return {
        ...state,
        currentTrackIndex: newCurrentTrackIndex,
        currentTrackList: newCurrentTrackList,
        undoStack: newUndoStack,
        isDeleteAnimationActive: false,
        sourcePlaylistEmpty: false,
      };
    });

    // was empty!
    if (sourcePlaylistEmpty) {
      get().loadNextTrack();
    }
  },

  undoBatchRemove: async (lastRemoveAction: UndoBatchAction) => {
    const axiosInstance = axiosService.getInstance();

    if (lastRemoveAction.removedFromPlaylist.isLikedSongs) {
      await axiosInstance.put(
        `${SpotifyApiPaths.MyTracks}?ids=${lastRemoveAction.removedTracks.map((track) => track.track.id).join(',')}`,
        null
      );
    } else {
      const result = await axiosInstance.post<{ snapshot_id: string }>(
        `${SpotifyApiPaths.Playlists}/${lastRemoveAction.removedFromPlaylist.id}/tracks`,
        {
          uris: lastRemoveAction.removedTracks.map((track) => track.track.uri),
        }
      );

      get().updateSnapshotIdOfSourcePlaylist(result.data.snapshot_id);
    }

    get().loadAllTracksOfCurrentPlaylist();
  },

  triggerDeleteAnimation: () => {
    set({ isDeleteAnimationActive: true });

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 310); // wait just a bit longer to be sure we finished the animation to prevent flashing screen
    });
  },

  openLikeOrderModal: () =>
    new Promise<DialogResults>((resolve) => {
      // open modal
      set({ likeOrderModalIsOpen: true });

      // set up close call back for modal
      const closeLikeOrderModal = (result: DialogResults) => {
        // on close; close modal, reset state and resolve the closing result
        set({ likeOrderModalIsOpen: false, closeLikeOrderModal: undefined });
        resolve(result);
      };

      // Attach the callback to the state
      set({ closeLikeOrderModal });
    }),

  setLikeOrderModalDontShowAgainStatus: (result) => {
    set({ likeOrderModalDontShowAgain: result });
  },

  async handleTransferAction(trackDirection: TrackDirections) {
    const { transferAction, sourcePlaylist, likeOrderModalDontShowAgain } = get();

    let dialogResult = DialogResults.Confirmed;

    // if action === move and source playlist === liked songs; warn about unliking
    if (
      transferAction === TransferActions.Move &&
      sourcePlaylist?.isLikedSongs &&
      !likeOrderModalDontShowAgain
    ) {
      dialogResult = await get().openLikeOrderModal();
    }

    if (dialogResult === DialogResults.Cancelled) {
      return;
    }

    // with both move and copy, add songs to target playlist
    const { currentTrack } = get();

    if (!currentTrack) {
      logError('Missing current track while adding songs to target playlists');
      return;
    }

    get().addTracksToSelectedTargetPlaylists([
      {
        uri: currentTrack.track.uri,
        id: currentTrack.track.id,
      },
    ]);

    // only on 'move' remove track from source playlist
    if (transferAction === TransferActions.Move) {
      // if we accept the warning without storing the decision (don't warn me again), we get the question again in the
      // delete flow, so we need to store the decision to prevent opening the modal twice
      set({ alreadyConfirmedForMoving: dialogResult === DialogResults.Confirmed });

      await get().removeTrackFromSourcePlaylist();

      set({ alreadyConfirmedForMoving: false });
    }

    if (trackDirection === TrackDirections.Next) {
      get().loadNextTrack();
    } else if (trackDirection === TrackDirections.Previous) {
      get().loadPreviousTrack();
    }
  },
});
