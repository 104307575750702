import { Checkbox, Flex } from '@mantine/core';
import clsx from 'clsx';
import * as React from 'react';
import classes from '../templates/selectPlaylist.module.css';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';
import { defaultGap } from '../../../constants/constants';

interface PlaylistTableHeaderProps {
  scrolled: boolean;
  headerRowItems?: React.ReactElement[];
  multiSelect: MultiSelectSettings;
}

export function PlaylistTableHeader({
  scrolled,
  headerRowItems,
  multiSelect,
}: Readonly<PlaylistTableHeaderProps>) {
  return (
    <Flex
      className={clsx(classes.tableHeader, {
        [classes.scrolled]: scrolled,
      })}
      align="center"
      gap={defaultGap}
    >
      <Checkbox
        radius="xl"
        checked={multiSelect.combinedChecked}
        onChange={() => multiSelect.combinedOnChange()}
        indeterminate={multiSelect.combinedIndeterminate}
        classNames={{ body: classes.body }}
      />
      <Flex align="center" gap="sm">
        {headerRowItems?.map((button) => button)}
      </Flex>
    </Flex>
  );
}
