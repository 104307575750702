import React from 'react';
import { Anchor, AppShell, Flex, ScrollArea, Text } from '@mantine/core';
import { IconMessage2Heart } from '@tabler/icons-react';
import { LinksGroup, LinksGroupProps } from '../molecules/navBarLinksGroup';
import classes from './navBar.module.css';
import { iconSize } from '../../../utils/iconSize';

export function NavBar({
  routes,
  close,
}: Readonly<{ routes: LinksGroupProps[]; close: () => void }>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const links = routes.map((item) => <LinksGroup closeParent={close} {...item} key={item.label} />);

  return (
    <>
      <AppShell.Section grow component={ScrollArea}>
        {links}
      </AppShell.Section>
      <AppShell.Section>
        <Flex justify="center">
          <Anchor
            href="https://github.com/TiceEnterprise/PlaylistPro/issues"
            target="_blank"
            underline="never"
            c="dark.2"
          >
            <Flex gap="0.2rem" align="center">
              <IconMessage2Heart style={iconSize(18)} />
              <Text size="sm">Feedback</Text>
            </Flex>
          </Anchor>
        </Flex>
        <Flex p="xs" justify="center">
          <Text>by&nbsp;</Text>
          <Anchor
            href="https://github.com/TiceEnterprise/PlaylistPro"
            target="_blank"
            underline="never"
            className={classes.gradientLink}
          >
            <Text fw={700}>TICE</Text>
          </Anchor>
        </Flex>
      </AppShell.Section>
    </>
  );
}
