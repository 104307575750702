import { useNavigate } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import * as React from 'react';
import { AppPaths } from '../../../constants/enums';
import { useCombinedStore } from '../../../stores/combinedStore';

export function LogOutMenuItem() {
  const navigate = useNavigate();
  const signOut = useCombinedStore((state) => state.signOut);

  return (
    <Menu.Item
      leftSection={<IconLogout size="1em" stroke={1.5} />}
      onClick={() => {
        signOut(() => navigate(AppPaths.Landing));
      }}
    >
      Logout
    </Menu.Item>
  );
}
