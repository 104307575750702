import { ScrollArea, Skeleton, Table } from '@mantine/core';
import * as React from 'react';
import {
  IconArmchair,
  IconBolt,
  IconBuildingStadium,
  IconCloudStorm,
  IconDisc,
  IconEggCracked,
  IconHeadphones,
  IconMicrophone2Off,
  IconMoodSing,
  IconMusic,
  IconPlugOff,
  IconPodium,
  IconRainbow,
  IconSparkles,
  IconUsb,
  IconZzz,
} from '@tabler/icons-react';
import { useCombinedStore } from '../../../stores/combinedStore';
import { AudioFeature } from '../atoms/audioFeature';
import classes from './audioFeatures.module.css';
import { AudioFeatureItem } from '../../../models/audioFeatureItem';

export function AudioFeatures() {
  const currentTrack = useCombinedStore((state) => state.currentTrack);

  if (!currentTrack) {
    return <Skeleton />;
  }

  const { audioFeatures } = currentTrack;

  if (!audioFeatures) {
    return <Skeleton />;
  }

  const audioFeatureItems: AudioFeatureItem[] = [
    {
      label: 'Popularity',
      value: currentTrack.track.popularity / 100,
      fromIcon: IconEggCracked,
      toIcon: IconSparkles,
      // fromIcon: IconSeeding,
      // fromIcon: IconAntennaBars1,
      // toIcon: IconBuildingBroadcastTower,
      // toIcon: IconTrendingUp,
      // toIcon: IconStar,
      // toIcon: IconAntennaBars5,
    },
    { label: 'Energy', value: audioFeatures.energy, fromIcon: IconZzz, toIcon: IconBolt },
    {
      label: 'Danceability',
      value: audioFeatures.danceability,
      fromIcon: IconArmchair,
      toIcon: IconDisc,
    },
    {
      label: 'Valence', // (musical positiveness: sad - euphoric)
      value: audioFeatures.valence,
      fromIcon: IconCloudStorm,
      toIcon: IconRainbow,
      // fromIcon: IconMoodLookDown,
      // fromIcon: IconCloudRain,
      // fromIcon: IconMoodNeutral,
      // toIcon: IconMoodHappy,
      // toIcon: IconSunHigh,
    },
    {
      label: 'Speechiness',
      value: audioFeatures.speechiness,
      fromIcon: IconMusic,
      toIcon: IconPodium,
      // toIcon: IconMicrophone2,
    },
    {
      label: 'Acousticness',
      value: audioFeatures.acousticness,
      fromIcon: IconUsb,
      toIcon: IconPlugOff,
      // fromIcon: IconDialpad,
      // fromIcon: IconWaveSquare,
      // fromIcon: IconWaveSine,
      // fromIcon: IconKeyboard,
      // fromIcon: IconAdjustments,
      // toIcon: IconGuitarPick,
      // toIcon: IconMicrophone,
      // toIcon: IconPlugX,
    },
    {
      label: 'Instrumentalness',
      value: audioFeatures.instrumentalness,
      fromIcon: IconMoodSing,
      toIcon: IconMicrophone2Off,
    },
    {
      label: 'Liveness',
      value: audioFeatures.liveness,
      fromIcon: IconHeadphones,
      toIcon: IconBuildingStadium,
    },
  ];

  return (
    <ScrollArea h={100}>
      <Table
        className={classes.featureTable}
        withRowBorders={false}
        horizontalSpacing="0.2rem"
        verticalSpacing="0.1rem"
      >
        <Table.Tbody>
          {audioFeatureItems.map((audioFeatureItem) => (
            <AudioFeature
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...audioFeatureItem}
              color={audioFeatureItem.color ?? currentTrack.albumColors?.Vibrant?.hex}
              key={audioFeatureItem.label}
            />
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
}
