import { SpotifyPlaylist } from '../models/spotifyApi.interface';

export function isPlaylistEditableByUser(playlist: SpotifyPlaylist, userId: string): boolean {
  return playlist.owner.id === userId || playlist.collaborative;
}

export function filterUserEditablePlaylists(
  playlists: SpotifyPlaylist[],
  userId: string
): SpotifyPlaylist[] {
  return playlists.filter((playlist) => isPlaylistEditableByUser(playlist, userId));
}
