import * as React from 'react';
import { Title } from '@mantine/core';
import { useCombinedStore } from '../../../stores/combinedStore';
import { SelectTargetPlaylists } from '../organisms/selectTargetPlaylists';
import { TrackCardSkeleton } from '../organisms/trackCard.skeleton';
import { TransferActionButtons } from '../organisms/transferActionButtons';
import { TrackCard } from '../organisms/trackCard';
import { NoSourcePlaylistInfo } from '../../shared/molecules/NoSourcePlaylistInfo';

export function TransferTracks() {
  const selectedSourcePlaylist = useCombinedStore((state) => state.sourcePlaylist);
  const currentTrack = useCombinedStore((state) => state.currentTrack);
  const loadingTrack = useCombinedStore((state) => state.loadingTrack);
  const sourcePlaylistEmpty = useCombinedStore((state) => state.sourcePlaylistEmpty);

  if (!selectedSourcePlaylist || sourcePlaylistEmpty) {
    return <NoSourcePlaylistInfo title="Transfer tracks" />;
  }

  return (
    <>
      <Title order={3} mb="sm" mt="md">
        Transfer tracks from {selectedSourcePlaylist.name}
      </Title>
      {loadingTrack ? (
        <TrackCardSkeleton />
      ) : (
        <>
          <TrackCard currentTrack={currentTrack} />
          <TransferActionButtons />
        </>
      )}
      <SelectTargetPlaylists />
    </>
  );
}
