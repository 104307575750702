import * as React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { sentryOptions } from './sentryOptions';
import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import './global.css';
import { App } from './app';

Sentry.init(sentryOptions);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
