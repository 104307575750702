import { SpotifyToken } from '../models/spotifyToken.interface';

export function hasTokenExpired(spotifyToken: SpotifyToken) {
  return Date.now() > spotifyToken.timeStamp + spotifyToken.expiresIn * 1000;
}

export function getSpotifyTokenFromUrlParams(urlParams: URLSearchParams): SpotifyToken {
  return {
    accessToken: urlParams.get('access_token'),
    refreshToken: urlParams.get('refresh_token'),
    expiresIn: parseInt(urlParams.get('expires_in') ?? '0', 10),
    timeStamp: Date.now(),
  };
}
