import Vibrant from 'node-vibrant/lib/bundle';
import { Palette } from '@vibrant/color';
import { logError } from './logError';

export const loadImageAsDataFromURL = async (url: string) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      }
    };
    img.onerror = reject;
  });

export const prefetchImage = (url: string) => {
  const img = new Image();
  img.src = url;
};

export const loadAlbumColors = async (imageUrl: string): Promise<Palette | null> => {
  try {
    return await Vibrant.from(imageUrl).maxColorCount(64).quality(5).getPalette();
  } catch (error) {
    logError('Error loading album colors:', error as Error);
    return null;
  }
};
