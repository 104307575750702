import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCombinedStore } from '../../../stores/combinedStore';
import { AppPaths } from '../../../constants/enums';
import { SelectPlaylist } from '../../selectPlaylistTemplate/templates/selectPlaylist';
import { usePreviousPath } from '../../../utils/navigationContext';

export function SelectSourcePlaylist() {
  const playlists = useCombinedStore((state) => state.userPlaylists);
  const setSourcePlaylist = useCombinedStore((state) => state.setSourcePlaylist);

  const navigate = useNavigate();
  const previousPath = usePreviousPath();

  function handleOnPlaylistClick() {
    return (clickedPlaylistId: string) => {
      setSourcePlaylist(clickedPlaylistId);
      if (previousPath === AppPaths.TransferTracks) {
        navigate(AppPaths.TransferTracks); // or batch transfer...
      } else if (previousPath === AppPaths.BatchTransfer) {
        navigate(AppPaths.BatchTransfer);
      } else {
        navigate(AppPaths.TransferTracks);
      }
    };
  }

  return (
    <SelectPlaylist
      heading="Select a playlist to transfer tracks from"
      playlists={playlists}
      onPlaylistClick={handleOnPlaylistClick()}
      refreshPlaylistsOnMount
    />
  );
}
