export enum AppPaths {
  Landing = '/',
  SelectSourcePlaylist = '/select-source-playlist',
  TransferTracks = '/transfer',
  BatchTransfer = '/batch-transfer',
  ApiLogin = '/api/auth/login',
  ApiRefresh = '/api/auth/refresh',
  EditTargetPlaylists = '/edit-target-playlists',
}

export enum SpotifyApiPaths {
  Me = '/me',
  MyTracks = '/me/tracks',
  Playlists = '/playlists',
  AudioFeatures = '/audio-features',
}

export enum TransferActions {
  Move = 'Move',
  Copy = 'Copy',
}

export enum DialogResults {
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
}

export enum TrackDirections {
  Next = 'Next',
  Previous = 'Previous',
}

export enum NotificationType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}
