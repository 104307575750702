import { ConfirmLikeOrderModal } from './confirmLikeOrderModal';
import { NewPlaylistModal } from '../molecules/newPlaylistModal';

export function Modals() {
  return (
    <>
      <ConfirmLikeOrderModal />
      <NewPlaylistModal />
    </>
  );
}
