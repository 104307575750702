import { StateCreator } from 'zustand/esm';
import axios, { CancelTokenSource } from 'axios';
import { nanoid } from 'nanoid/non-secure';
import type { CombinedSlices } from './combinedStore';
import { SpotifyPaging, SpotifyTrackItem } from '../models/spotifyApi.interface';
import { axiosService } from '../utils/axiosInstance';
import { logError } from '../utils/logError';
import { limit } from './playlistStore';
import { loadTrackDetailsUtil } from '../utils/loadTrackDetailsUtil';

interface TrackActionState {
  currentTrackList: SpotifyTrackItem[];
  currentTrack: SpotifyTrackItem | undefined;
  currentTrackIndex: number;
  loadingTrack: boolean; // only for first track of playlist
  loadingPlaylist: boolean;
  cancelToken: CancelTokenSource | null;
  loadingTrackDetailsPromises: Map<string, Promise<SpotifyTrackItem | null>>;
}

export interface TrackActionSlice extends TrackActionState {
  resetTracksState: () => void;
  setLoadingPromise: (trackId: string, promise: Promise<SpotifyTrackItem | null>) => void;
  clearLoadingPromise: (trackId: string) => void;
  loadAllTracksOfCurrentPlaylist: () => Promise<void>;
  loadTrack: (trackIndex: number) => Promise<void>;
  loadTrackDetails: (trackIndex: number) => Promise<SpotifyTrackItem | null>;
  triggerBackgroundBuffering: () => void;
  loadNextTrack: () => void;
  loadPreviousTrack: () => void;
}

export const trackActionSliceInitialSate: TrackActionState = {
  currentTrackList: [],
  currentTrack: undefined,
  currentTrackIndex: 0,
  loadingTrack: false,
  loadingPlaylist: true,
  cancelToken: null,
  loadingTrackDetailsPromises: new Map(),
};

export const createTrackActionSlice: StateCreator<CombinedSlices, [], [], TrackActionSlice> = (
  set,
  get
) => ({
  ...trackActionSliceInitialSate,

  resetTracksState: () => {
    set({
      currentTrackList: [],
      currentTrack: undefined,
      loadingPlaylist: true,
      currentTrackIndex: 0,
    });
  },

  setLoadingPromise: (trackId: string, promise: Promise<SpotifyTrackItem | null>) => {
    set((state) => {
      const loadingPromises = new Map(state.loadingTrackDetailsPromises);
      loadingPromises.set(trackId, promise);
      return { loadingTrackDetailsPromises: loadingPromises };
    });

    // Automatically remove the promise from the map once it resolves or rejects
    promise
      .then(() => {
        get().clearLoadingPromise(trackId);
      })
      .catch(() => {
        get().clearLoadingPromise(trackId);
      });
  },

  clearLoadingPromise: (trackId: string) => {
    set((state) => {
      const loadingPromises = new Map(state.loadingTrackDetailsPromises);
      loadingPromises.delete(trackId);
      return { loadingTrackDetailsPromises: loadingPromises };
    });
  },

  loadTrack: async (trackIndex: number) => {
    let currentTrack = get().currentTrackList?.[trackIndex];

    if (!currentTrack) {
      logError('No current track while loading track');
      return;
    }

    if (currentTrack.detailsLoaded) {
      // nothing more to do, current track has details loaded
    } else if (get().loadingTrackDetailsPromises.has(currentTrack.track.id)) {
      const possibleTrack = await get().loadingTrackDetailsPromises.get(currentTrack.track.id);
      if (!possibleTrack) {
        logError(`Could not load track details of track which already started loading`);
        return;
      }
      currentTrack = possibleTrack;
    } else {
      const possibleTrack = await get().loadTrackDetails(trackIndex);
      if (!possibleTrack) {
        logError(`Could not load track details of not loaded track`);
        return;
      }
      currentTrack = possibleTrack;
    }

    if (get().animationPromise !== undefined) {
      await get().animationPromise;
    }

    // Update trackIndex state and set track with audio features (if applicable) to the current track
    set({
      currentTrackIndex: trackIndex,
      currentTrack,
      loadingTrack: false,
      animationPromise: undefined,
      isDeleteAnimationActive: false,
    });
    get().triggerBackgroundBuffering();
  },

  loadTrackDetails: async (trackIndex: number): Promise<SpotifyTrackItem | null> => {
    const { currentTrackList } = get();
    const track = currentTrackList[trackIndex];

    if (!track) {
      logError('No current track while loading track details');
      return null;
    }

    // Set loading state
    set((state) => {
      const updatedTrackList = [...state.currentTrackList];
      updatedTrackList[trackIndex] = { ...track, detailsLoading: true };
      return { currentTrackList: updatedTrackList };
    });

    try {
      const trackWithAlbumAndAudioFeatures = await loadTrackDetailsUtil(track);

      set((state) => {
        const updatedTrackList = [...state.currentTrackList];
        updatedTrackList[trackIndex] = {
          ...trackWithAlbumAndAudioFeatures,
          detailsLoaded: true,
          detailsLoading: false,
        };
        return { currentTrackList: updatedTrackList };
      });

      return trackWithAlbumAndAudioFeatures;
    } catch (error) {
      logError('Error loading track details', error as Error);
      set((state) => {
        const updatedTrackList = [...state.currentTrackList];
        updatedTrackList[trackIndex] = { ...track, detailsLoading: false };
        return { currentTrackList: updatedTrackList };
      });
      return null;
    }
  },

  triggerBackgroundBuffering: () => {
    const { currentTrackIndex, currentTrackList } = get();
    const bufferSize = 2; // tried 5 before, but that hit my rate limit eventually
    const bufferIndices: number[] = [];

    // Creating the buffer indices
    for (let i = -bufferSize; i <= bufferSize; i++) {
      bufferIndices.push(
        (currentTrackIndex + i + currentTrackList.length) % currentTrackList.length
      );
    }

    // Prepare an array of promises for tracks within the buffer indices
    const loadingPromises = bufferIndices
      .map((index) => {
        const track = currentTrackList[index];
        if (track && !track.detailsLoaded && !track.detailsLoading) {
          // Set loading state
          set((state) => {
            const updatedTrackList = [...state.currentTrackList];
            updatedTrackList[index] = { ...track, detailsLoading: true };
            return { currentTrackList: updatedTrackList };
          });

          // Return the promise for loading track details
          return get()
            .loadTrackDetails(index)
            .then((trackDetails) => {
              // Clear the loading promise once the track details have been loaded
              get().clearLoadingPromise(track.track.id);
              return trackDetails;
            })
            .catch((error) => {
              // Also clear the loading promise if an error occurs
              get().clearLoadingPromise(track.track.id);
              logError('Error loading track details', error as Error);
              return null;
            });
        }
        return null;
      })
      .filter((promise) => promise !== null); // Filter out null values

    // Use Promise.all to wait for all loading operations to complete
    Promise.all(loadingPromises);
  },

  loadNextTrack: () => {
    const { currentTrackIndex, currentTrackList } = get();
    const nextTrackIndex = (currentTrackIndex + 1) % currentTrackList.length;
    get().loadTrack(nextTrackIndex);
  },

  loadPreviousTrack: () => {
    const { currentTrackIndex, currentTrackList } = get();
    const previousTrackIndex =
      (currentTrackIndex - 1 + currentTrackList.length) % currentTrackList.length;
    get().loadTrack(previousTrackIndex);
  },

  loadAllTracksOfCurrentPlaylist: async () => {
    // cancel the previous request
    if (get().cancelToken) {
      get().cancelToken?.cancel();
    }

    // initialize the new token and store it
    const cancelToken = axios.CancelToken.source();
    set({ cancelToken });

    const { sourcePlaylist, userProfile } = get();
    const totalTracks = sourcePlaylist?.tracks.total ?? 0;

    get().resetTracksState();

    const promises = Array.from({ length: Math.ceil(totalTracks / limit) }, (_, index) => {
      const offset = index * limit;
      const url = `${sourcePlaylist?.href}/tracks?offset=${offset}&limit=${limit}${
        userProfile?.country ? `&market=${userProfile?.country}` : ''
      }`;

      return axiosService.getInstance().get<SpotifyPaging<SpotifyTrackItem>>(url, {
        cancelToken: cancelToken.token,
      });
    });

    const firstCall = promises.shift();

    const firstResponse = await firstCall;

    if (firstResponse && firstResponse.data.items.length > 0) {
      set((state) => ({
        ...state,
        currentTrackList: [
          ...(state.currentTrackList ?? []),
          ...firstResponse.data.items.map((track) => ({
            ...track,
            uniqueId: nanoid(), // we need our own id, as tracks with the same id might appear in same playlist multiple times
          })),
        ],
      }));
      get().loadTrack(0);
    } else if (firstResponse && firstResponse.data.items.length === 0) {
      set({
        currentTrackList: [],
        currentTrackIndex: -1,
        sourcePlaylistEmpty: true,
        loadingTrack: false,
        animationPromise: undefined,
        isDeleteAnimationActive: false,
      });
    }

    const responses = await Promise.all(promises);

    responses.forEach((response) => {
      if (response.data.items.length > 0) {
        set((state) => ({
          ...state,
          currentTrackList: [
            ...(state.currentTrackList ?? []),
            ...response.data.items.map((track) => ({
              ...track,
              uniqueId: nanoid(), // we need our own id, as tracks with the same id might appear in same playlist multiple times
            })),
          ],
        }));
      }
    });

    get().updateTargetCheckboxState();

    // emit that we're done
    set({ loadingPlaylist: false });
  },
});
