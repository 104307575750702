import * as React from 'react';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCombinedStore } from '../../stores/combinedStore';
import { AppPaths } from '../../constants/enums';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const handleURLChange = useCombinedStore((state) => state.handleURLChange);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    handleURLChange(location, navigate);
  }, [location, navigate, handleURLChange]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function RequireAuth({ children }: { children: React.ReactElement }) {
  const spotifyToken = useCombinedStore((state) => state.spotifyToken);
  const location = useLocation();

  if (!spotifyToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={AppPaths.Landing} state={{ from: location }} replace />;
  }

  return children;
}
