import { useIdle } from '@mantine/hooks';
import * as React from 'react';
import { StrictMode, useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { BrowserRouter } from 'react-router-dom';
import { mantineTheme } from './theme';
import { useCombinedStore } from './stores/combinedStore';
import { AuthProvider } from './features/authentication/authProvider';
import AppShellAndRouting from './features/route/pages/appShellAndRouting';
import { Modals } from './features/shared/organisms/modals';

export function App() {
  const setUserIsIdle = useCombinedStore((state) => state.setUserIsIdle);

  // Configure the idle hook (e.g., become idle after 15 minutes of inactivity)
  const isIdle = useIdle(15 * 60 * 1000, { initialState: false });

  useEffect(() => {
    setUserIsIdle(isIdle);
  }, [isIdle, setUserIsIdle]);

  return (
    <StrictMode>
      <MantineProvider theme={mantineTheme} defaultColorScheme="dark">
        <Notifications position="top-right" limit={7} />
        <BrowserRouter>
          <AuthProvider>
            <Modals />
            <AppShellAndRouting />
          </AuthProvider>
        </BrowserRouter>
      </MantineProvider>
    </StrictMode>
  );
}
