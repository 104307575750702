import React, { useEffect, useState } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import { Button, RingProgress, Tooltip } from '@mantine/core';
import classes from '../../shared/styles/trackControlsAndButtons.module.css';
import { logError } from '../../../utils/logError';
import {
  controlButtonIconSize,
  controlButtonRingProgressThickness,
  controlButtonSize,
} from '../../../constants/constants';

interface AudioPlayerProps {
  songUrl: string;
}

export function AudioPreview({ songUrl }: Readonly<AudioPlayerProps>) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  useEffect(() => {
    setProgress(0);
  }, [songUrl]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();

      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [songUrl, isPlaying]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        // Check if the audio is not already playing before attempting to play
        if (!isPlaying) {
          audioRef.current
            .play()
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              logError('Error while toggling play/pause', error);
            });
        }
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const { currentTime, duration } = audioRef.current;
      const progressPercent = (currentTime / duration) * 100;
      setProgress(progressPercent);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setProgress(0);
  };

  if (!songUrl) {
    return (
      <Tooltip
        label="Preview not available"
        withArrow
        transitionProps={{ transition: 'fade', duration: 300 }}
        events={{ hover: true, focus: true, touch: false }}
        openDelay={500}
        closeDelay={100}
        position="bottom"
        radius="md"
      >
        <Button
          variant="outline"
          color="gray"
          size="xl"
          className={classes.playbackButton}
          disabled
        >
          <IconPlayerPlayFilled size={controlButtonIconSize} />
        </Button>
      </Tooltip>
    );
  }
  return (
    <>
      <Button
        variant="outline"
        color="gray"
        size="xl"
        onClick={togglePlay}
        className={classes.playbackButton}
        aria-label="Play/Pause audio preview"
      >
        {isPlaying ? (
          <IconPlayerPauseFilled size={controlButtonIconSize} />
        ) : (
          <IconPlayerPlayFilled size={controlButtonIconSize} />
        )}
        {progress > 0 && (
          <RingProgress
            sections={[{ value: progress, color: 'white' }]}
            size={controlButtonSize}
            roundCaps
            thickness={controlButtonRingProgressThickness}
            rootColor="transparent"
            className={classes.ringProgress}
          />
        )}
      </Button>
      <audio src={songUrl} ref={audioRef} onEnded={handleEnded} onTimeUpdate={handleTimeUpdate}>
        <track kind="captions" label="Track preview" />
      </audio>
    </>
  );
}
