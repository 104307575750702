import { Checkbox, Flex, rem, Skeleton, useMantineTheme } from '@mantine/core';
import * as React from 'react';
import classes from './playlistRow.module.css';
import {
  defaultGap,
  playlistRowImageRadius,
  playlistRowImageSize,
  smallGap,
} from '../../../constants/constants';

export function PlaylistRowSkeleton({
  showCheckBox,
  style,
}: {
  showCheckBox?: boolean;
  style?: React.CSSProperties;
}) {
  const theme = useMantineTheme();

  return (
    <Flex className={classes.tableRow} align="center" gap={defaultGap} style={style}>
      {showCheckBox ? <Checkbox disabled /> : null}
      <Skeleton
        height={playlistRowImageSize}
        width={playlistRowImageSize}
        radius={playlistRowImageRadius}
      />
      <Flex direction="column" gap={smallGap}>
        <Skeleton height={theme.fontSizes.md} radius="xl" width={rem(180)} />
        <Skeleton height={theme.fontSizes.sm} radius="xl" width={rem(180)} />
      </Flex>
    </Flex>
  );
}
