import { DEFAULT_THEME, MantineTheme } from '@mantine/core';

export const mantineTheme: MantineTheme = {
  ...DEFAULT_THEME,
  colors: {
    raspberry: [
      '#ffe8f5',
      '#ffd0e5',
      '#fd9ec6',
      '#fa69a6',
      '#f73e8b',
      '#f7227a',
      '#f71172', // primary: 6
      '#dd0160',
      '#c60056',
      '#ae0049',
    ],
    magentaPink: [
      '#ffecf9',
      '#f8d8ed',
      '#ecb0d5',
      '#e084be',
      '#d55faa',
      '#cf489d',
      '#cd3b97', // primary: 6
      '#b62d83',
      '#a32575',
      '#8f1966',
    ],
    ...DEFAULT_THEME.colors,
    dark: [
      '#AEC1C8',
      '#93A2AF',
      '#7F8F9D',
      '#5A6C7C',
      '#354757',
      '#2B3947',
      '#252F3D',
      '#1A232F',
      '#141B26',
      '#10141C',
    ],
  },
  primaryShade: 6,
  primaryColor: 'raspberry',
  fontFamily:
    'Inter, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
  defaultRadius: 'xl',
  defaultGradient: { from: 'raspberry', to: 'magentaPink', deg: 90 },
};
