import { notifications } from '@mantine/notifications';
import { Button, Flex } from '@mantine/core';
import * as React from 'react';
import { IconArrowBackUp } from '@tabler/icons-react';
import classes from './playlistDeletionNotification.module.css';
import { smallIconSize } from '../../../constants/constants';

export function batchRemoveNotification(
  playlistName: string,
  numberOfTracks: number,
  callback: () => void
) {
  const id = notifications.show({
    title: 'Tracks removed',
    message: (
      <Flex align="center">
        {`${numberOfTracks} have been removed from ${playlistName}.
        You can undo this action, but the tracks will be added at the start of the playlist,
        not at their original position.`}
        <Button
          ml="sm"
          size="xs"
          variant="light"
          classNames={{ root: classes.sweepButton }}
          leftSection={<IconArrowBackUp style={smallIconSize} />}
          onClick={() => {
            callback();
            notifications.hide(id);
          }}
        >
          Undo
        </Button>
      </Flex>
    ),
    radius: 'sm',
    color: 'blue',
    autoClose: 10000,
  });
}
