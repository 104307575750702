import { IconBrandSpotify, IconListCheck, IconTransform } from '@tabler/icons-react';
import { LinksGroupProps } from '../molecules/navBarLinksGroup';
import { AppPaths } from '../../../constants/enums';
import { useCombinedStore } from '../../../stores/combinedStore';

export const routes: LinksGroupProps[] = [
  {
    label: 'Set up',
    icon: IconListCheck,
    initiallyOpened: true,
    subLinks: [
      { label: 'Select source playlist', link: AppPaths.SelectSourcePlaylist },
      { label: 'Edit target playlists', link: AppPaths.EditTargetPlaylists },
    ],
  },
  {
    label: 'Actions',
    icon: IconTransform,
    initiallyOpened: true,
    subLinks: [
      { label: 'Transfer tracks', link: AppPaths.TransferTracks },
      { label: 'Batch Transfer', link: AppPaths.BatchTransfer },
      { label: 'More coming soon!', disabled: true },
    ],
  },
  {
    label: 'Your Account',
    icon: IconBrandSpotify,
    subLinks: [
      {
        label: 'Log out',
        link: AppPaths.Landing,
        onInvoke: (navigateToLink) => useCombinedStore.getState().signOut(navigateToLink),
      },
    ],
  },
];
