import { StateCreator } from 'zustand/esm';
import axios from 'axios';
import { Location, NavigateFunction } from 'react-router-dom';
import type { CombinedSlices } from './combinedStore';
import { environment } from '../environments/environment';
import { SpotifyToken } from '../models/spotifyToken.interface';
import { AppPaths } from '../constants/enums';
import { axiosService } from '../utils/axiosInstance';
import { SpotifyTokenRefreshResult } from '../models/apiInterfaces';
import { getSpotifyTokenFromUrlParams, hasTokenExpired } from '../utils/spotifyToken.utils';
import { signedOutWarning } from '../constants/messages';

interface AuthState {
  spotifyToken: SpotifyToken | null;
}

export interface AuthSlice extends AuthState {
  initiateLogin: () => void;
  handleURLChange: (location: Location, navigate: NavigateFunction) => void;
  handleTokenFromUrl: (urlParams: URLSearchParams) => SpotifyToken | null;
  setAccessTokenAndUpdateAxiosService: (newAccessToken: SpotifyToken) => void;
  tryTokenFromStorage: () => Promise<SpotifyToken | null>;
  doRefresh: (refreshToken?: string) => Promise<SpotifyToken | null>;
  signOut: (callback?: VoidFunction) => void;
}

export const authSliceInitialState: AuthState = {
  spotifyToken: null,
};

export const createAuthSlice: StateCreator<CombinedSlices, [], [], AuthSlice> = (set, get) => ({
  ...authSliceInitialState,

  initiateLogin() {
    window.location.href = AppPaths.ApiLogin;
  },

  handleURLChange: (location: Location, navigate: NavigateFunction) => {
    let spotifyToken;
    // if we have search query parameters, store the related tokens
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      spotifyToken = get().handleTokenFromUrl(urlParams);

      if (spotifyToken) {
        // strip the search parameters
        navigate({ pathname: location.pathname, search: '' });
        return;
      }
    }

    spotifyToken = get().tryTokenFromStorage();

    // fall through; no valid spotifyToken found, make sure we (re-)set that
    if (!spotifyToken) {
      get().signOut();
    }
  },

  handleTokenFromUrl: (urlParams: URLSearchParams): SpotifyToken | null => {
    const spotifyTokenFromUrlParams = getSpotifyTokenFromUrlParams(urlParams);

    if (!urlParams.get('error') && spotifyTokenFromUrlParams?.accessToken) {
      get().setAccessTokenAndUpdateAxiosService(spotifyTokenFromUrlParams);
      return spotifyTokenFromUrlParams;
    }
    return null;
  },

  setAccessTokenAndUpdateAxiosService: async (newSpotifyToken: SpotifyToken) => {
    if (newSpotifyToken.accessToken !== get().spotifyToken?.accessToken) {
      localStorage.setItem(environment.spotifyStorageKey, JSON.stringify(newSpotifyToken));
      set({ spotifyToken: newSpotifyToken });
      axiosService.setInformUser(get().informUser);
      axiosService.setTokenRefresh(get().doRefresh);
      axiosService.setToken(newSpotifyToken);
      await get().getCurrentUserProfile();
      get().loadAllPlaylists(false);
    }
  },

  tryTokenFromStorage: async (): Promise<SpotifyToken | null> => {
    const spotifyTokenFromStorage = localStorage.getItem(environment.spotifyStorageKey);

    if (spotifyTokenFromStorage) {
      const parsedSpotifyToken: SpotifyToken = JSON.parse(spotifyTokenFromStorage);

      // ... if valid and not expired, set it and return it.
      if (parsedSpotifyToken.accessToken && !hasTokenExpired(parsedSpotifyToken)) {
        // no need to update the storage as we got this from the storage
        get().setAccessTokenAndUpdateAxiosService(parsedSpotifyToken);
        return parsedSpotifyToken;
      }

      // if the token expired, refresh it
      if (parsedSpotifyToken.refreshToken) {
        return get().doRefresh(parsedSpotifyToken.refreshToken);
      }
    }

    return null;
  },

  doRefresh: async (refreshTokenIn?: string | null): Promise<SpotifyToken | null> => {
    const refreshToken = refreshTokenIn ?? get().spotifyToken?.refreshToken;

    if (!refreshToken) {
      get().informUser(signedOutWarning);
      get().signOut();
    } else {
      const { data } = await axios.get<SpotifyTokenRefreshResult>(
        `${AppPaths.ApiRefresh}?${new URLSearchParams({ refresh_token: refreshToken })}`
      );

      if (data.access_token) {
        const spotifyTokenFromRefresh: SpotifyToken = {
          accessToken: data.access_token,
          expiresIn: data.expires_in,
          refreshToken,
          timeStamp: Date.now(),
        };

        get().setAccessTokenAndUpdateAxiosService(spotifyTokenFromRefresh);

        return spotifyTokenFromRefresh;
      }
    }
    return null;
  },

  signOut(callback?: VoidFunction) {
    window.localStorage.removeItem(environment.spotifyStorageKey);
    axiosService.cleanupAndClear();
    get().reset();
    if (callback) {
      callback();
    }
  },
});
