import { Box, Center } from '@mantine/core';
import { IconCopy, IconDragDrop2 } from '@tabler/icons-react';
import * as React from 'react';
import { TransferActions } from '../../../constants/enums';
import { smallIconSize } from '../../../constants/constants';

export function toggleLabel(transferAction: TransferActions) {
  return (
    <Center>
      {transferAction === TransferActions.Copy ? (
        <IconCopy style={smallIconSize} />
      ) : (
        <IconDragDrop2 style={smallIconSize} />
      )}
      <Box ml={8}>{transferAction}</Box>
    </Center>
  );
}
