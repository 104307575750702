import { AxiosResponse } from 'axios';
import { Palette } from '@vibrant/color';
import {
  SpotifyAlbumExtended,
  SpotifyAudioFeatures,
  SpotifyTrackItem,
} from '../models/spotifyApi.interface';
import { loadAlbumColors, loadImageAsDataFromURL } from './loadImageAsDataFromURL';
import { axiosService } from './axiosInstance';
import { SpotifyApiPaths } from '../constants/enums';

export async function loadTrackDetailsUtil(track: SpotifyTrackItem) {
  // handle empty state of local track on front end
  if (track.is_local) {
    return track;
  }

  const trackId = track.track.id;
  const currentTrackAlbumImageUrl = track?.track.album.images[0].url;

  // Start loading the image and album colors
  const loadImagePromise = loadImageAsDataFromURL(currentTrackAlbumImageUrl);
  const loadAlbumColorsPromise = loadAlbumColors(currentTrackAlbumImageUrl);

  const axiosInstance = axiosService.getInstance();

  // Initiate API requests for additional album info and track like-status
  const additionalAlbumInfoPromise = axiosInstance.get<SpotifyAlbumExtended>(
    track.track.album.href
  );
  const isLikedPromise = axiosInstance.get<boolean[]>(
    `${SpotifyApiPaths.MyTracks}/contains?ids=${trackId}`
  );

  // Create an array of promises for the API calls (with or without audio features)
  const apiPromises: Promise<AxiosResponse | string | Palette | null>[] = [
    additionalAlbumInfoPromise,
    isLikedPromise,
    loadImagePromise,
    loadAlbumColorsPromise,
  ];

  // If the track is not an episode, add the audio features API call to the promises array
  if (track.track.type !== 'episode') {
    const audioFeaturesPromise = axiosInstance.get<SpotifyAudioFeatures>(
      `${SpotifyApiPaths.AudioFeatures}/${trackId}`
    );
    apiPromises.push(audioFeaturesPromise);
  }

  // await all the promises
  const [albumResponse, isCurrentTrackLiked, imgDataUrl, albumColors, featuresResponse] =
    await Promise.all(apiPromises);

  // Construct the track object based on the API responses
  // All on one object works best, as it then loads at the same time in the component
  const trackWithAlbumAndAudioFeatures: SpotifyTrackItem = {
    ...track,
    additionalAlbumInfo: (albumResponse as AxiosResponse).data,
    liked: (isCurrentTrackLiked as AxiosResponse).data[0],
    albumImage: imgDataUrl as string,
    albumColors: albumColors as Palette,
  };

  // If featuresResponse is defined, it means audio features were retrieved
  if (featuresResponse) {
    trackWithAlbumAndAudioFeatures.audioFeatures = (featuresResponse as AxiosResponse).data;
  }
  // After loading, update the track with the new details and set detailsLoaded to true

  return trackWithAlbumAndAudioFeatures;
}
