import { StateCreator } from 'zustand/esm';
import type { CombinedSlices } from './combinedStore';

interface EditTargetPlaylistState {
  anyEditTargetPlaylistSelected: boolean;
  allEditTargetPlaylistSelected: boolean;
  clickedSelectAllEditTargetPlaylist: boolean;
  targetPlaylistSelection: Set<string>;
}

export interface EditTargetPlaylistSlice extends EditTargetPlaylistState {
  toggleTargetPlaylistSelectionCurrentPlaylist: (playlistId: string) => void;
  removeFromTargetPlaylistSelection: (playlistId: string) => void;
  updateTargetPlaylistSelectionCheckboxState: () => void;
  toggleTargetPlaylistSelectionCheckbox: () => void;
  setAllEditablePlaylistsAsInTargetPlaylistSelection: () => void;
  setAllEditablePlaylistsAsNotInTargetPlaylistSelection: () => void;
}

export const editTargetPlaylistSliceInitialState: EditTargetPlaylistState = {
  anyEditTargetPlaylistSelected: false,
  allEditTargetPlaylistSelected: false,
  clickedSelectAllEditTargetPlaylist: true,
  targetPlaylistSelection: new Set(),
};

export const createEditTargetPlaylistsSlice: StateCreator<
  CombinedSlices,
  [],
  [],
  EditTargetPlaylistSlice
> = (set, get) => ({
  ...editTargetPlaylistSliceInitialState,

  toggleTargetPlaylistSelectionCurrentPlaylist(playlistId) {
    set((state) => {
      const newSelectedIds = new Set(state.targetPlaylistSelection);
      if (newSelectedIds.has(playlistId)) {
        newSelectedIds.delete(playlistId);
      } else {
        newSelectedIds.add(playlistId);
      }
      return { ...state, targetPlaylistSelection: newSelectedIds };
    });

    get().updateTargetPlaylistSelectionCheckboxState();
  },

  removeFromTargetPlaylistSelection(playlistId) {
    if (get().targetPlaylistSelection.has(playlistId)) {
      set((state) => {
        const newSelectedIds = new Set(state.targetPlaylistSelection);
        newSelectedIds.delete(playlistId);
        return { ...state, targetPlaylistSelection: newSelectedIds };
      });
    }
  },

  updateTargetPlaylistSelectionCheckboxState() {
    set((state) => ({
      ...state,
      anyEditTargetPlaylistSelected: state.targetPlaylistSelection.size > 0,
      allEditTargetPlaylistSelected:
        state.userEditablePlaylists?.length === state.targetPlaylistSelection.size,
    }));
  },

  setAllEditablePlaylistsAsInTargetPlaylistSelection() {
    set((state) => ({
      ...state,
      targetPlaylistSelection: new Set(state.userEditablePlaylists?.map((playlist) => playlist.id)),
    }));
  },

  setAllEditablePlaylistsAsNotInTargetPlaylistSelection() {
    set({ targetPlaylistSelection: new Set() });
  },

  toggleTargetPlaylistSelectionCheckbox() {
    const {
      anyEditTargetPlaylistSelected,
      allEditTargetPlaylistSelected,
      clickedSelectAllEditTargetPlaylist,
    } = get();

    const noEditTargetSelected = !anyEditTargetPlaylistSelected;

    if (clickedSelectAllEditTargetPlaylist) {
      get().setAllEditablePlaylistsAsNotInTargetPlaylistSelection();
    } else {
      if (anyEditTargetPlaylistSelected || noEditTargetSelected) {
        get().setAllEditablePlaylistsAsInTargetPlaylistSelection();
      }

      if (allEditTargetPlaylistSelected) {
        get().setAllEditablePlaylistsAsNotInTargetPlaylistSelection();
      }
    }
    // Toggle the clickedSelectAll state
    set({ clickedSelectAllEditTargetPlaylist: !clickedSelectAllEditTargetPlaylist });

    get().updateTargetPlaylistSelectionCheckboxState();
  },
});
