import { Progress, Skeleton, Table } from '@mantine/core';
import * as React from 'react';
import classes from './audioFeature.module.css';
import { xsIconPlaceholderHeight } from '../../../constants/constants';

export function AudioFeatureSkeleton() {
  return (
    <Table.Tr>
      <Table.Td className={classes.pl0}>
        <Skeleton visible height={xsIconPlaceholderHeight}>
          Instrumentalness
        </Skeleton>
      </Table.Td>
      <Table.Td>
        <Skeleton visible height={xsIconPlaceholderHeight}>
          100% and Iconnn
        </Skeleton>
      </Table.Td>
      <Table.Td className={`${classes.progressCell} ${classes.pr10}`}>
        <Progress value={0} radius="md" size="lg" />
      </Table.Td>
    </Table.Tr>
  );
}
