import * as React from 'react';
import { ReactElement } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AppShell, Container } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RequireAuth } from '../../authentication/authProvider';
import { LandingPage } from '../../landing/pages/landingPage';
import { SelectSourcePlaylist } from '../../selectSourcePlaylist/pages/selectSourcePlaylist';
import { TransferTracks } from '../../transferTracks/pages/transferTracks';
import { AppPaths } from '../../../constants/enums';
import { EditTargetPlaylists } from '../../editTargetPlaylists/pages/editTargetPlaylists';
import { NavBar } from '../organisms/navBar';
import HeaderBar from '../organisms/headerBar';
import { routes } from './routes';
import classes from './appShellAndRouting.module.css';
import { BatchTransfer } from '../../batchTransfer/pages/batchTransfer';
import { NavigationProvider } from '../../../utils/navigationContext';

interface AppShellTemplateProps {
  HeaderContent: ReactElement;
  NavBarContent?: ReactElement;
  MainContent: ReactElement;
  navbarProps?: {
    width: { sm: number; lg: number };
    breakpoint: string;
    collapsed: { mobile: boolean };
  };
}

function AppShellTemplate({
  HeaderContent,
  NavBarContent,
  MainContent,
  navbarProps = undefined,
}: AppShellTemplateProps) {
  return (
    <AppShell
      padding={{ base: 'xs', sm: 'md', md: 'lg', lg: 'xl' }}
      header={{ height: 60 }}
      navbar={navbarProps}
      classNames={{
        navbar: classes.navBar,
        header: classes.header,
        main: classes.main,
        root: classes.root,
      }}
    >
      <AppShell.Header>{HeaderContent}</AppShell.Header>
      {NavBarContent && <AppShell.Navbar>{NavBarContent}</AppShell.Navbar>}
      <AppShell.Main>{MainContent}</AppShell.Main>
    </AppShell>
  );
}

export default function AppShellAndRouting() {
  const [opened, { toggle, close }] = useDisclosure();

  const LandingShell = (
    <AppShellTemplate
      HeaderContent={<HeaderBar showBurger={false} />}
      MainContent={<LandingPage />}
    />
  );

  const AuthShell = (
    <RequireAuth>
      <AppShellTemplate
        HeaderContent={<HeaderBar showBurger opened={opened} toggle={toggle} />}
        NavBarContent={<NavBar routes={routes} close={close} />}
        MainContent={
          <Container className={classes.outletContainer}>
            <Outlet />
          </Container>
        }
        navbarProps={{
          width: { sm: 250, lg: 300 },
          breakpoint: 'md',
          collapsed: { mobile: !opened },
        }}
      />
    </RequireAuth>
  );

  return (
    <NavigationProvider>
      <Routes>
        <Route path={AppPaths.Landing} element={LandingShell} />
        <Route element={AuthShell}>
          <Route path={AppPaths.SelectSourcePlaylist} element={<SelectSourcePlaylist />} />
          <Route path={AppPaths.TransferTracks} element={<TransferTracks />} />
          <Route path={AppPaths.BatchTransfer} element={<BatchTransfer />} />
          <Route path={AppPaths.EditTargetPlaylists} element={<EditTargetPlaylists />} />
        </Route>
      </Routes>
    </NavigationProvider>
  );
}
