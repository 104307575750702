import * as React from 'react';
import { IconArrowRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { Box, Button, SegmentedControl, Tooltip } from '@mantine/core';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from './transferButtons.module.css';
import { toggleLabel } from '../atoms/toggleLabel';
import { TrackDirections, TransferActions } from '../../../constants/enums';
import { smallIconSize } from '../../../constants/constants';

export function TransferActionButtons({ batchTransfer = false }: { batchTransfer?: boolean }) {
  const sourcePlaylist = useCombinedStore((state) => state.sourcePlaylist);
  const transferAction = useCombinedStore((state) => state.transferAction);
  const setTransferAction = useCombinedStore((state) => state.setTransferAction);
  const currentTrack = useCombinedStore((state) => state.currentTrack);
  const currentTrackIndex = useCombinedStore((state) => state.currentTrackIndex);
  const loadingPlaylist = useCombinedStore((state) => state.loadingPlaylist);
  const handleTransferAction = useCombinedStore((state) => state.handleTransferAction);
  const doBatchTransfer = useCombinedStore((state) => state.doBatchTransfer);

  return (
    <Box className={classes.transferButtonRow} mt={batchTransfer ? 'md' : undefined}>
      {batchTransfer ? (
        <Button
          type="button"
          onClick={() => doBatchTransfer()}
          rightSection={<IconArrowRight style={smallIconSize} stroke={3} />}
          aria-label={`${transferAction} all selected tracks`}
        >
          {toggleLabel(transferAction)}
        </Button>
      ) : (
        <Button.Group>
          <Button
            type="button"
            loading={loadingPlaylist && currentTrackIndex === 0}
            disabled={currentTrack?.is_local}
            onClick={async () => handleTransferAction(TrackDirections.Previous)}
            leftSection={<IconChevronsLeft style={smallIconSize} stroke={3} />}
            aria-label={`Previous track and ${transferAction}`}
          >
            {toggleLabel(transferAction)}
          </Button>
          <Button
            type="button"
            disabled={currentTrack?.is_local}
            onClick={() => handleTransferAction(TrackDirections.Next)}
            rightSection={<IconChevronsRight style={smallIconSize} stroke={3} />}
            aria-label={`Next track and ${transferAction}`}
          >
            {toggleLabel(transferAction)}
          </Button>
        </Button.Group>
      )}
      <Tooltip
        label={`'Copy' keeps the track in the source playlist. 'Move' moves the track to the target playlists, removing it from the source playlist. ${
          !sourcePlaylist?.userEditable
            ? "Disabled as you don't have edit permissions for this playlist."
            : ''
        }`}
        multiline
        w={300}
        withArrow
        transitionProps={{ transition: 'fade', duration: 300 }}
        events={{ hover: true, focus: true, touch: false }}
        openDelay={1000}
        closeDelay={100}
        position="bottom"
        radius="md"
      >
        <SegmentedControl
          value={transferAction}
          onChange={(value: string) => setTransferAction(value as TransferActions)}
          transitionDuration={500}
          transitionTimingFunction="linear"
          disabled={!sourcePlaylist?.userEditable || currentTrack?.is_local}
          data={[
            {
              label: toggleLabel(TransferActions.Copy),
              value: TransferActions.Copy,
            },
            {
              label: toggleLabel(TransferActions.Move),
              value: TransferActions.Move,
            },
          ]}
        />
      </Tooltip>
    </Box>
  );
}
