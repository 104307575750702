import * as React from 'react';
import { Button, Flex, Text } from '@mantine/core';
import { IconCheck, IconInfoCircleFilled, IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { SelectPlaylist } from '../../selectPlaylistTemplate/templates/selectPlaylist';
import { useCombinedStore } from '../../../stores/combinedStore';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';
import classes from './editTargetPlaylists.module.css';
import {
  defaultGap,
  smallGap,
  smallIconSize,
  warningFlexPadding,
} from '../../../constants/constants';

function DoneEditingButton({
  onClick,
  disabled,
}: Readonly<{ onClick: () => void; disabled: boolean }>) {
  return (
    <Button
      key="done"
      onClick={onClick}
      size="xs"
      leftSection={<IconCheck style={smallIconSize} />}
      disabled={disabled}
      aria-label="Done editing target playlists"
    >
      Done
    </Button>
  );
}

function AddNewPlaylistButton({
  onClick,
  disabled,
}: Readonly<{ onClick: () => void; disabled: boolean }>) {
  return (
    <Button
      key="add"
      onClick={onClick}
      size="xs"
      disabled={disabled}
      aria-label="Add new playlist"
      className={classes.addButton}
    >
      <IconPlus style={smallIconSize} />
    </Button>
  );
}

export function EditTargetPlaylists() {
  const playlists = useCombinedStore((state) => state.userEditablePlaylists);
  const targetPlaylistSelection = useCombinedStore((state) => state.targetPlaylistSelection);
  const anyEditTargetPlaylistSelected = useCombinedStore(
    (state) => state.anyEditTargetPlaylistSelected
  );
  const allEditTargetPlaylistSelected = useCombinedStore(
    (state) => state.allEditTargetPlaylistSelected
  );
  const clickedSelectAllEditTargetPlaylist = useCombinedStore(
    (state) => state.clickedSelectAllEditTargetPlaylist
  );
  const toggleTargetPlaylistSelectionCheckbox = useCombinedStore(
    (state) => state.toggleTargetPlaylistSelectionCheckbox
  );
  const toggleTargetPlaylistSelectionCurrentPlaylist = useCombinedStore(
    (state) => state.toggleTargetPlaylistSelectionCurrentPlaylist
  );
  const startAddPlaylist = useCombinedStore((state) => state.mountPlaylistAddRow);
  const playlistAddRowMounted = useCombinedStore((state) => state.playlistAddRowMounted);
  const deletePlaylist = useCombinedStore((state) => state.deletePlaylist);

  const navigate = useNavigate();

  const noEditTargetSelected = !anyEditTargetPlaylistSelected;

  const multiSelect: MultiSelectSettings = {
    combinedChecked: clickedSelectAllEditTargetPlaylist || allEditTargetPlaylistSelected, // or only anyEditTargetPlaylistSelected or only all selected; difference between green or black indeterminate dash
    combinedIndeterminate: !(noEditTargetSelected || allEditTargetPlaylistSelected),
    combinedOnChange: () => toggleTargetPlaylistSelectionCheckbox(),
    isSelectedCheck: (playlist) => targetPlaylistSelection.has(playlist.id),
    playlistAddRowMounted,
    onDelete: (playlist) => deletePlaylist(playlist.id),
  };

  function handleDoneClick() {
    navigate(-1);
  }

  const titleElement = (
    <Text fw={700} key="title">
      All playlists
    </Text>
  );

  const headerRowItems = [
    titleElement,
    <DoneEditingButton
      onClick={() => handleDoneClick()}
      disabled={!anyEditTargetPlaylistSelected}
      key="done"
    />,
    <AddNewPlaylistButton
      onClick={() => startAddPlaylist()}
      disabled={playlistAddRowMounted}
      key="add"
    />,
  ];

  const noTargetPlaylistSelectedWarning = (
    <Flex
      key="warning"
      gap={{ base: defaultGap, sm: smallGap }}
      align="center"
      p={{ base: warningFlexPadding, sm: 0 }}
    >
      <IconInfoCircleFilled
        style={{ color: 'var(--mantine-color-red-filled)', ...smallIconSize }}
      />{' '}
      <Text c="red" variant="gradient" gradient={{ from: 'red', to: 'orange' }} fw={700} size="sm">
        Select at least one target playlist
      </Text>
    </Flex>
  );

  return (
    <SelectPlaylist
      heading="Edit the list of target playlists"
      playlists={playlists}
      onPlaylistClick={toggleTargetPlaylistSelectionCurrentPlaylist}
      multiSelect={multiSelect}
      headerRowItems={headerRowItems}
      warningElement={!anyEditTargetPlaylistSelected ? noTargetPlaylistSelectedWarning : undefined}
    />
  );
}
