import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Button, Flex, rem, Title } from '@mantine/core';
import clsx from 'clsx';
import { IconRefresh } from '@tabler/icons-react';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from './selectPlaylist.module.css';
import { PlaylistTableHeader } from '../organisms/playlistTableHeader';
import { PlaylistList } from '../organisms/playlistList';
import buttonClasses from '../../shared/styles/trackControlsAndButtons.module.css';
import { SpotifyPlaylist } from '../../../models/spotifyApi.interface';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';
import { smallGap, smallIconSize } from '../../../constants/constants';

interface SelectPlaylistProps {
  heading: string;
  playlists?: SpotifyPlaylist[];
  onPlaylistClick: (playlistId: string) => void;
  multiSelect?: MultiSelectSettings;
  headerRowItems?: React.ReactElement[];
  warningElement?: React.ReactElement;
  refreshPlaylistsOnMount?: boolean;
}

export function SelectPlaylist({
  heading,
  playlists,
  onPlaylistClick,
  multiSelect,
  headerRowItems,
  warningElement,
  refreshPlaylistsOnMount,
}: SelectPlaylistProps) {
  const isPlaylistsLoading = useCombinedStore((state) => state.playlistsLoading);
  const isPlaylistRefreshing = useCombinedStore((state) => state.playlistsRefreshing);
  const playlistsLoading = useCombinedStore((state) => state.playlistsLoading);
  const loadAllPlaylists = useCombinedStore((state) => state.loadAllPlaylists);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (refreshPlaylistsOnMount && !playlistsLoading) {
      loadAllPlaylists(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshButton = (
    <Button
      variant="outline"
      key="refreshButton"
      size="md"
      mt={multiSelect ? 0 : rem(5.5)}
      onClick={() => loadAllPlaylists(true)}
      aria-label="Refresh Playlists"
      className={clsx(buttonClasses.refreshButton, {
        [buttonClasses.spin]: isPlaylistsLoading || isPlaylistRefreshing,
      })}
    >
      <IconRefresh style={smallIconSize} />
    </Button>
  );

  return (
    <Flex className={classes.selectPlaylistFlex}>
      <Flex
        align="center"
        gap={smallGap}
        className={clsx({ [classes.scrolled]: scrolled && !multiSelect })}
      >
        <Title order={3} className={classes.titleHeader}>
          {heading}
        </Title>
        {!multiSelect && refreshButton}
      </Flex>
      {multiSelect && (
        <Flex direction={{ base: 'column', sm: 'row' }}>
          <PlaylistTableHeader
            scrolled={scrolled}
            headerRowItems={[...(headerRowItems as ReactElement[]), refreshButton]}
            multiSelect={multiSelect}
          />
          {warningElement}
        </Flex>
      )}
      <PlaylistList
        playlists={playlists}
        onPlaylistClick={onPlaylistClick}
        multiSelect={multiSelect}
        setScrolled={setScrolled}
      />
    </Flex>
  );
}
