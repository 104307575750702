import { Button, Flex, Loader, Skeleton, Tooltip } from '@mantine/core';
import {
  IconHeart,
  IconHeartFilled,
  IconPlayerTrackNextFilled,
  IconPlayerTrackPrevFilled,
  IconPlaylistX,
} from '@tabler/icons-react';
import * as React from 'react';
import clsx from 'clsx';
import { AudioPreview } from '../molecules/audioPreview';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from '../../shared/styles/trackControlsAndButtons.module.css';
import { UndoButton } from '../molecules/undoButton';
import { controlButtonIconSize, playlistIconSize } from '../../../constants/constants';

export function TrackControls() {
  const removeTrackFromSourcePlaylist = useCombinedStore(
    (state) => state.removeTrackFromSourcePlaylist
  );
  const loadingPlaylist = useCombinedStore((state) => state.loadingPlaylist);
  const currentTrackIndex = useCombinedStore((state) => state.currentTrackIndex);
  const currentTrack = useCombinedStore((state) => state.currentTrack);
  const likingTrackInProgress = useCombinedStore((state) => state.likingTrackInProgress);
  const toggleLikedSong = useCombinedStore((state) => state.toggleLikedSong);
  const sourcePlaylist = useCombinedStore((state) => state.sourcePlaylist);
  const loadNextTrack = useCombinedStore((state) => state.loadNextTrack);
  const loadPreviousTrack = useCombinedStore((state) => state.loadPreviousTrack);

  // State to determine the animation type
  const [likeIconAnimation, setLikeIconAnimation] = React.useState('');

  if (!currentTrack) {
    return <Skeleton />;
  }
  const handleToggleLikedSong = () => {
    setLikeIconAnimation(currentTrack.liked ? classes.likeShake : classes.likePop);
    toggleLikedSong();

    // Reset the animation class after the animation ends
    setTimeout(() => {
      setLikeIconAnimation('');
    }, 350); // Reset after the duration of the animation
  };

  return (
    <Flex direction="column">
      <Flex gap={5} align="center">
        <Tooltip
          label={
            sourcePlaylist?.userEditable
              ? 'Remove this track from the current playlist'
              : "Source playlist is not editable by you, so you can't remove this track"
          }
          withArrow
          transitionProps={{ transition: 'fade', duration: 300 }}
          events={{ hover: true, focus: true, touch: false }}
          openDelay={500}
          closeDelay={100}
          position="bottom"
          multiline
          w={290}
          radius="md"
        >
          <div>
            <Button
              variant="outline"
              color="gray"
              size="md"
              onClick={removeTrackFromSourcePlaylist}
              className={classes.likeButton}
              disabled={!sourcePlaylist?.userEditable || currentTrack.is_local}
              aria-label="Remove track from source playlist"
            >
              <IconPlaylistX style={playlistIconSize} />
            </Button>
          </div>
        </Tooltip>
        <Button
          variant="outline"
          color="gray"
          size="xl"
          disabled={loadingPlaylist && currentTrackIndex === 0}
          onClick={() => loadPreviousTrack()}
          className={classes.playbackButton}
          aria-label="Previous Track"
        >
          {loadingPlaylist && currentTrackIndex === 0 ? (
            <Loader color="gray" />
          ) : (
            <IconPlayerTrackPrevFilled size={controlButtonIconSize} />
          )}
        </Button>
        <AudioPreview songUrl={currentTrack.track.preview_url} />
        <Button
          variant="outline"
          color="gray"
          size="xl"
          onClick={() => loadNextTrack()}
          className={classes.playbackButton}
          aria-label="Next track"
        >
          <IconPlayerTrackNextFilled size={controlButtonIconSize} />
        </Button>
        <Button
          variant="outline"
          color="gray"
          size="sm"
          onClick={handleToggleLikedSong}
          className={clsx(classes.likeButton, likeIconAnimation)}
          disabled={likingTrackInProgress || currentTrack.is_local}
          aria-label={`Toggle Like song, currently: ${currentTrack.liked ? 'Liked' : 'not Liked'}`}
        >
          {currentTrack.liked ? (
            <IconHeartFilled style={playlistIconSize} />
          ) : (
            <IconHeart style={playlistIconSize} />
          )}
        </Button>
      </Flex>
      <UndoButton />
    </Flex>
  );
}
