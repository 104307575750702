import { Avatar, Flex, Skeleton, Text } from '@mantine/core';
import * as React from 'react';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from './userMenuButton.module.css';
import { ChevronIcon } from '../atoms/chevronIcon';

export function UserMenuButton({ userMenuOpened }: Readonly<{ userMenuOpened: boolean }>) {
  const profile = useCombinedStore((state) => state.userProfile);
  const profileLoading = useCombinedStore((state) => state.userProfileLoading);

  if (profileLoading) {
    return (
      <Flex align="center" gap={7}>
        <Skeleton radius="md" style={{ width: 30, height: 30 }} />
        <Skeleton radius="sm" style={{ width: 50, height: 17 }} />
        <ChevronIcon userMenuOpened={userMenuOpened} />
      </Flex>
    );
  }

  if (!profile) {
    return null;
  }

  return (
    <Flex align="center" gap={7}>
      <Avatar
        src={profile.images[0]?.url ?? null}
        alt={`${profile.display_name}'s profile picture`}
        radius="md"
        size={30}
      />
      <Text size="md" className={classes.userName}>
        {profile.display_name}
      </Text>
      <ChevronIcon userMenuOpened={userMenuOpened} />
    </Flex>
  );
}
