import { Flex, Text, Title } from '@mantine/core';
import * as React from 'react';
import { useState } from 'react';
import { smallGap } from '../../../constants/constants';
import classes from './selectPlaylist.module.css';
import { PlaylistTableHeader } from '../organisms/playlistTableHeader';
import { MultiSelectSettings } from '../../../models/multiSelectSettings';
import { TrackList } from '../organisms/trackList';
import { useCombinedStore } from '../../../stores/combinedStore';

export function SelectTrackList() {
  const [scrolled, setScrolled] = useState(false);

  const toggleAllTracksCheckbox = useCombinedStore((state) => state.toggleAllTracksCheckbox);
  const anyTrackSelected = useCombinedStore((state) => state.anyTrackSelected);
  const allTracksSelected = useCombinedStore((state) => state.allTracksSelected);
  const clickedSelectAllTracks = useCombinedStore((state) => state.clickedSelectAllTracks);

  const noTrackSelected = !anyTrackSelected;

  const multiSelect: MultiSelectSettings = {
    combinedChecked: clickedSelectAllTracks || allTracksSelected,
    combinedIndeterminate: !(noTrackSelected || allTracksSelected),
    combinedOnChange: toggleAllTracksCheckbox,
  };

  const headerRowItem = (
    <Text fw={700} key="track title">
      All tracks
    </Text>
  );

  return (
    <Flex className={classes.selectPlaylistFlex}>
      <Flex align="center" gap={smallGap}>
        <Title order={3} className={classes.titleHeader}>
          Select tracks to...
        </Title>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }}>
        <PlaylistTableHeader
          scrolled={scrolled}
          headerRowItems={[headerRowItem]}
          multiSelect={multiSelect}
        />
      </Flex>
      <TrackList setScrolled={setScrolled} />
    </Flex>
  );
}
