import React, { memo, useRef } from 'react';
import { ActionIcon, Avatar, Checkbox, Flex, Text } from '@mantine/core';
import { IconForbid, IconLock, IconPlaylist, IconUsersGroup, IconWorld } from '@tabler/icons-react';
import { useInView } from 'framer-motion';
import { SpotifyPlaylist } from '../../../models/spotifyApi.interface';
import classes from './playlistRow.module.css';
import {
  defaultGap,
  inViewMargin,
  playlistIconSize,
  playlistRowImageRadius,
  playlistRowImageSize,
  singleLineLineClamp,
  smallGap,
  smallIconSize,
  xsIconSize,
} from '../../../constants/constants';

interface PlaylistRowProps {
  playlist: SpotifyPlaylist;
  onPlaylistClick: (playlist: string) => void;
  showCheckBox: boolean;
  disabled?: boolean;
  isSelectedCheck?: (playlist: SpotifyPlaylist) => boolean;
  onDelete?: (playlist: SpotifyPlaylist) => void;
}

export const PlaylistRow = memo(
  ({
    playlist,
    onPlaylistClick,
    showCheckBox,
    disabled,
    isSelectedCheck,
    onDelete,
  }: PlaylistRowProps) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
      margin: inViewMargin,
    });

    const handlePlaylistRowClick = () => {
      onPlaylistClick(playlist.id);
    };

    const handlePlaylistRowKey = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onPlaylistClick(playlist.id);
      }
    };

    // prevent the toggle action from being done twice
    const handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
    };

    function onClickDelete() {
      return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        if (onDelete) {
          onDelete(playlist);
        }
      };
    }

    return (
      <Flex
        ref={ref}
        onClick={disabled ? () => {} : handlePlaylistRowClick}
        className={classes.tableRow}
        aria-disabled={disabled || undefined}
        tabIndex={0}
        role="button"
        onKeyUp={disabled ? () => {} : handlePlaylistRowKey}
        direction="row"
        align="center"
        justify="space-between"
      >
        {isInView ? (
          <>
            <Flex direction="row" align="center" gap={defaultGap}>
              {showCheckBox ? (
                <Checkbox
                  radius="xl"
                  checked={isSelectedCheck ? isSelectedCheck(playlist) : false}
                  onChange={handlePlaylistRowClick}
                  onClick={handleCheckboxClick}
                />
              ) : null}
              <Avatar
                size={playlistRowImageSize}
                src={playlist.images ? playlist.images[playlist.images.length - 1]?.url : undefined}
                radius={playlistRowImageRadius}
                alt={`Playlist cover image of ${playlist.name}`}
              >
                <IconPlaylist style={playlistIconSize} />
              </Avatar>
              <Flex direction="row" align="center">
                <Flex direction="column">
                  <Text lineClamp={singleLineLineClamp} className={classes.playlistName}>
                    {playlist.name}
                  </Text>
                  <Flex gap={smallGap} align="center">
                    <Text lineClamp={singleLineLineClamp} size="sm" className={classes.subText}>
                      {`${playlist.owner.display_name} • ${playlist.tracks.total} songs`}
                    </Text>
                    {playlist.public ? (
                      <IconWorld style={xsIconSize} className={classes.subText} />
                    ) : (
                      <IconLock style={xsIconSize} className={classes.subText} />
                    )}
                    {playlist.collaborative ? (
                      <IconUsersGroup style={xsIconSize} className={classes.subText} />
                    ) : null}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {onDelete && !playlist.isLikedSongs ? (
              <ActionIcon variant="light" radius="sm" ml="xs" onClick={onClickDelete()}>
                <IconForbid style={{ ...smallIconSize, transform: 'rotate(-45deg)' }} />
              </ActionIcon>
            ) : null}
          </>
        ) : (
          <div className={classes.emptyRow} />
        )}
      </Flex>
    );
  }
);

PlaylistRow.displayName = 'PlaylistRow';
