import { StateCreator } from 'zustand/esm';
import { NotificationData, notifications } from '@mantine/notifications';
import {
  SpotifyPaging,
  SpotifyTrackItem,
  SpotifyUserProfile,
} from '../models/spotifyApi.interface';
import type { CombinedSlices } from './combinedStore';
import { axiosService } from '../utils/axiosInstance';
import {
  DialogResults,
  NotificationType,
  SpotifyApiPaths,
  TransferActions,
} from '../constants/enums';
import { logError } from '../utils/logError';

interface UserState {
  userProfile?: SpotifyUserProfile;
  userProfileLoading: boolean;
  numberOfLikedSongs?: number;
  transferAction: TransferActions;
  userIsIdle: boolean;
}

export interface UserSlice extends UserState {
  getCurrentUserProfile: () => Promise<void>;
  loadNumberOfLikedSongs: () => Promise<void>;
  setTransferAction: (transferAction: TransferActions) => void;
  setUserIsIdle: (active: boolean) => void;
  likeOrderModalResult: () => Promise<DialogResults>;
  informUser: (notification: NotificationData & { type: NotificationType }) => void;
}

export const userSliceInitialState: UserState = {
  userProfile: undefined,
  userProfileLoading: false,
  numberOfLikedSongs: undefined,
  transferAction: TransferActions.Copy,
  userIsIdle: false,
};

export const createUserSlice: StateCreator<CombinedSlices, [], [], UserSlice> = (set, get) => ({
  ...userSliceInitialState,

  getCurrentUserProfile: async () => {
    set({ userProfileLoading: true });
    const response = await axiosService.getInstance().get<SpotifyUserProfile>(SpotifyApiPaths.Me);
    set({ userProfile: response.data, userProfileLoading: false });
    get().loadNumberOfLikedSongs();
  },

  loadNumberOfLikedSongs: async () => {
    try {
      const response = await axiosService
        .getInstance()
        .get<SpotifyPaging<SpotifyTrackItem>>(SpotifyApiPaths.MyTracks);
      set({ numberOfLikedSongs: response.data.total });
    } catch (error) {
      logError('Error loading number of liked songs', error as Error);
      set({ numberOfLikedSongs: 0 }); // Set to 0 if there's an error, somewhere else we check for undefined
    }
  },

  setTransferAction: (transferAction: TransferActions) => {
    set({ transferAction });
  },

  setUserIsIdle: (isIdle: boolean) => {
    set({ userIsIdle: isIdle });
    axiosService.setUserIsIdle(isIdle);
  },

  async likeOrderModalResult() {
    const { likeOrderModalDontShowAgain, alreadyConfirmedForMoving } = get();

    let dialogResult = DialogResults.Confirmed;

    if (!likeOrderModalDontShowAgain && !alreadyConfirmedForMoving) {
      dialogResult = await get().openLikeOrderModal();
    }
    return dialogResult;
  },

  /* eslint-disable no-param-reassign */
  informUser: (notification: NotificationData & { type: NotificationType }) => {
    notification.radius = notification.radius ?? 'sm';
    notification.autoClose = notification.autoClose ?? 10000;

    switch (notification.type) {
      case NotificationType.Error:
        notification.color = 'red';
        delete notification.autoClose;
        break;
      case NotificationType.Warning:
        notification.color = 'orange';
        break;
      case NotificationType.Info:
      default:
        notification.color = 'blue';
        break;
    }

    notifications.show(notification);
  },
  /* eslint-enable no-param-reassign */
});
