import { Anchor, Card, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from '../styles/transferTrack.module.css';
import { AppPaths } from '../../../constants/enums';
import { UndoButton } from '../../transferTracks/molecules/undoButton';

export function NoSourcePlaylistInfo({ title }: { title: string }) {
  const sourcePlaylistEmpty = useCombinedStore((state) => state.sourcePlaylistEmpty);
  const selectedSourcePlaylist = useCombinedStore((state) => state.sourcePlaylist);

  return (
    <>
      <Title order={3} mb="sm" mt="md">
        {title}
        {selectedSourcePlaylist?.name ? ` from ${selectedSourcePlaylist.name}` : ''}
      </Title>
      <Card className={classes.trackCardNeutralBackground}>
        <Text>
          {!selectedSourcePlaylist && 'No source playlist selected. You can select one '}
          {sourcePlaylistEmpty &&
            'That was the last track of this playlist. Undo your last action, or select a new source playlist '}
          <Anchor component={Link} to={AppPaths.SelectSourcePlaylist}>
            in this step
          </Anchor>
          .
        </Text>
        {sourcePlaylistEmpty && <UndoButton defaultButtonStyle />}
      </Card>
    </>
  );
}
