import { rem } from '@mantine/core';
import React from 'react';

interface AddressBookIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function PlaylistProIcon({ size, style, ...others }: AddressBookIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      style={{ width: rem(size), height: rem(size), ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      <path d="M10.1344 17.6215C10.1344 17.9491 10.1989 18.2734 10.3243 18.576C10.4496 18.8786 10.6333 19.1535 10.8649 19.3851C11.0965 19.6167 11.3715 19.8005 11.6741 19.9258C11.9767 20.0512 12.301 20.1157 12.6286 20.1157C12.9561 20.1157 13.2804 20.0512 13.583 19.9258C13.8856 19.8005 14.1606 19.6167 14.3922 19.3851C14.6238 19.1535 14.8075 18.8786 14.9328 18.576C15.0582 18.2734 15.1227 17.9491 15.1227 17.6215C15.1227 17.294 15.0582 16.9697 14.9328 16.6671C14.8075 16.3645 14.6238 16.0895 14.3922 15.8579C14.1606 15.6263 13.8856 15.4426 13.583 15.3172C13.2804 15.1919 12.9561 15.1274 12.6286 15.1274C12.301 15.1274 11.9767 15.1919 11.6741 15.3172C11.3715 15.4426 11.0965 15.6263 10.8649 15.8579C10.6333 16.0895 10.4496 16.3645 10.3243 16.6671C10.1989 16.9697 10.1344 17.294 10.1344 17.6215Z" />
      <path d="M15.1227 8.19367H20.1609V5.5" />
      <path d="M15.1227 17.7712V5.5H20.1609" />
      <path d="M7.49063 13.4563C7.49063 13.163 7.60713 12.8818 7.81449 12.6744C8.02186 12.467 8.30311 12.3505 8.59637 12.3505C8.88963 12.3505 9.17087 12.467 9.37824 12.6744C9.58561 12.8818 9.7021 13.163 9.7021 13.4563C9.7021 13.7495 9.58561 14.0308 9.37824 14.2381C9.17087 14.4455 8.88963 14.562 8.59637 14.562C8.30311 14.562 8.02186 14.4455 7.81449 14.2381C7.60713 14.0308 7.49063 13.7495 7.49063 13.4563Z" />
      <path d="M3.5 13.4813H7.49063" />
      <path d="M4.62237 10.1391C4.62237 9.84582 4.73886 9.56457 4.94623 9.3572C5.1536 9.14984 5.43484 9.03334 5.7281 9.03334C6.02136 9.03334 6.30261 9.14984 6.50998 9.3572C6.71734 9.56457 6.83384 9.84582 6.83384 10.1391C6.83384 10.4323 6.71734 10.7136 6.50998 10.9209C6.30261 11.1283 6.02136 11.2448 5.7281 11.2448C5.43484 11.2448 5.1536 11.1283 4.94623 10.9209C4.73886 10.7136 4.62237 10.4323 4.62237 10.1391Z" />
      <path d="M3.51663 10.1391H4.62237" />
      <path d="M6.83384 10.1391H12.3625" />
      <path d="M9.63559 6.85514C9.63559 6.56188 9.75209 6.28063 9.95945 6.07327C10.1668 5.8659 10.4481 5.7494 10.7413 5.7494C11.0346 5.7494 11.3158 5.8659 11.5232 6.07327C11.7306 6.28063 11.8471 6.56188 11.8471 6.85514C11.8471 7.1484 11.7306 7.42965 11.5232 7.63701C11.3158 7.84438 11.0346 7.96088 10.7413 7.96088C10.4481 7.96088 10.1668 7.84438 9.95945 7.63701C9.75209 7.42965 9.63559 7.1484 9.63559 6.85514Z" />
      <path d="M3.51663 6.8219H9.59818" />
      <path d="M11.8803 6.8468L12.3791 6.8468" />
    </svg>
  );
}
