import * as React from 'react';
import { Button, Tooltip } from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import classes from '../../shared/styles/trackControlsAndButtons.module.css';
import { useCombinedStore } from '../../../stores/combinedStore';
import { playlistIconSize } from '../../../constants/constants';

export function UndoButtonPlaceholder() {
  return <Button className={classes.undoButtonPlaceholder} size="md" variant="outline" disabled />;
}

export function UndoButton({
  defaultButtonStyle = false,
}: Readonly<{ defaultButtonStyle?: boolean }>) {
  const undoStack = useCombinedStore((state) => state.undoStack);
  const undoRemove = useCombinedStore((state) => state.undoRemove);

  const lastRemoveAction = undoStack[undoStack.length - 1];

  // Render placeholder when there is no action to undo
  if (!lastRemoveAction) {
    return <UndoButtonPlaceholder />;
  }

  return (
    <Tooltip
      label={`Undo the removal of '${lastRemoveAction.removedTrack.track.artists
        .map((artist) => artist.name)
        .join(', ')} - ${lastRemoveAction.removedTrack.track.name}' from ${
        lastRemoveAction.removedFromPlaylist.name
      }`}
      withArrow
      transitionProps={{ transition: 'fade', duration: 300 }}
      events={{ hover: true, focus: true, touch: false }}
      openDelay={500}
      closeDelay={100}
      position="bottom"
      multiline
      radius="md"
      w={290}
    >
      <Button
        variant={defaultButtonStyle ? 'filled' : 'outline'}
        size="md"
        onClick={() => undoRemove()}
        className={defaultButtonStyle ? classes.undoButtonDefaultStyle : classes.undoButton}
        aria-label="Undo last remove"
        leftSection={defaultButtonStyle ? <IconArrowBackUp style={playlistIconSize} /> : undefined}
      >
        {defaultButtonStyle ? 'Undo' : <IconArrowBackUp style={playlistIconSize} />}
      </Button>
    </Tooltip>
  );
}
