import { SpotifyPlaylist, SpotifyUserProfile } from '../models/spotifyApi.interface';
import { environment } from '../environments/environment';
import likedSongsImage from '../static/images/liked-songs.png';
import { SpotifyApiPaths } from '../constants/enums';
import { likedSongsId } from '../constants/constants';

export function initializeLikedSongs(
  userProfile: SpotifyUserProfile,
  numberOfLikedSongs?: number
): SpotifyPlaylist {
  return {
    owner: userProfile,
    tracks: {
      href: `${environment.spotifyApiBaseUrl}${SpotifyApiPaths.MyTracks}`,
      total: numberOfLikedSongs ?? 0,
    },
    href: `${environment.spotifyApiBaseUrl}/me`,
    images: [{ height: 300, width: 300, url: likedSongsImage }],
    name: 'Liked Songs',
    description: 'Liked Songs',
    id: likedSongsId,
    uri: 'spotify:collection:tracks',
    type: 'Playlist',
    collaborative: false,
    public: false,
    snapshot_id: '',
    external_urls: { spotify: '' },
    isLikedSongs: true,
  };
}
