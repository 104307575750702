export function getMusicalKeyString(pitchClass?: number, mode?: 0 | 1, separator = ' / '): string {
  const keys: Record<string, string> = {
    '0,1': `8B${separator}C`,
    '1,1': `3B${separator}C♯, D♭`,
    '2,1': `10B${separator}D`,
    '3,1': `5B${separator}D♯, E♭`,
    '4,1': `12B${separator}E`,
    '5,1': `7B${separator}F`,
    '6,1': `2B${separator}F♯, G♭`,
    '7,1': `9B${separator}G`,
    '8,1': `4B${separator}G♯, A♭`,
    '9,1': `11B${separator}A`,
    '10,1': `6B${separator}A♯, B♭`,
    '11,1': `1B${separator}B`,
    '0,0': `5A${separator}Cm`,
    '1,0': `12A${separator}D♭m`,
    '2,0': `7A${separator}Dm`,
    '3,0': `2A${separator}E♭m`,
    '4,0': `9A${separator}Em`,
    '5,0': `4A${separator}Fm`,
    '6,0': `11A${separator}G♭m`,
    '7,0': `6A${separator}Gm`,
    '8,0': `1A${separator}A♭m`,
    '9,0': `8A${separator}Am`,
    '10,0': `3A${separator}B♭m`,
    '11,0': `10A${separator}Bm`,
  };

  const key = `${pitchClass},${mode}`;
  return keys[key] ?? '';
}
