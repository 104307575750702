import { alpha, Card, Flex, Image } from '@mantine/core';
import clsx from 'clsx';
import * as React from 'react';
import { SpotifyTrackItem } from '../../../models/spotifyApi.interface';
import { useCombinedStore } from '../../../stores/combinedStore';
import classes from '../../shared/styles/transferTrack.module.css';
import { TrackControls } from './trackControls';
import emptyTrack from '../../../static/images/empty-playlist.png';
import { TrackInfo } from './trackInfo';

export function TrackCard({ currentTrack }: Readonly<{ currentTrack?: SpotifyTrackItem }>) {
  const isDeleteAnimationActive = useCombinedStore((state) => state.isDeleteAnimationActive);

  // the fallbacks colors are among others for local files
  const topColor = currentTrack?.albumColors?.Vibrant?.hex ?? alpha('#F71272', 0.35);
  const bottomColor = currentTrack?.albumColors?.Muted?.hex ?? alpha('#FFFFFF', 0.25);

  const cardStyleWithGradient = {
    '--gradient-top-color': topColor,
    '--gradient-bottom-color': bottomColor,
  };

  return (
    <Card className={classes.trackCard} style={cardStyleWithGradient}>
      <Flex className={classes.trackCardContents}>
        <Flex align="center" direction="column">
          <Image
            width="100%"
            src={currentTrack?.albumImage}
            alt={`album cover for ${currentTrack?.track.name} by ${currentTrack?.track.artists[0].name}`}
            className={clsx(classes.trackImageInSkeletonContainer, {
              [classes.genieEffect]: isDeleteAnimationActive,
            })}
            fallbackSrc={emptyTrack}
          />
          <TrackControls />
        </Flex>
        <TrackInfo />
      </Flex>
    </Card>
  );
}
