import { StateCreator } from 'zustand/esm';
import { AxiosResponse } from 'axios';
import type { CombinedSlices } from './combinedStore';
import { axiosService } from '../utils/axiosInstance';
import { SpotifyApiPaths } from '../constants/enums';
import { logError } from '../utils/logError';
import { SpotifyPlaylist } from '../models/spotifyApi.interface';
import { likedSongsId } from '../constants/constants';
import { noTargetPlaylistInfo } from '../constants/messages';

interface AddTrackInfo {
  uri: string;
  id: string;
}

interface TargetPlaylistState {
  anyTargetSelected: boolean;
  allTargetsSelected: boolean;
  clickedSelectAllTarget: boolean;
  selectedTargetPlaylistIds: Set<string>;
}

export interface TargetPlaylistSlice extends TargetPlaylistState {
  toggleTargetCurrentPlaylist: (playlistId: string) => void;
  removeFromSelectedTargetPlaylistIds: (playlistId: string) => void;
  updateTargetCheckboxState: () => void;
  toggleTargetCheckbox: () => void;
  setAllEditablePlaylistsAndInTargetSelectionAsTarget: () => void;
  setAllEditablePlaylistsAsNotTarget: () => void;
  addTracksToSelectedTargetPlaylists: (tracks: AddTrackInfo[]) => Promise<void>;
}

export const targetPlaylistSliceInitialState: TargetPlaylistState = {
  anyTargetSelected: false,
  allTargetsSelected: false,
  clickedSelectAllTarget: false,
  selectedTargetPlaylistIds: new Set(),
};

export const createTargetPlaylistSlice: StateCreator<
  CombinedSlices,
  [],
  [],
  TargetPlaylistSlice
> = (set, get) => ({
  ...targetPlaylistSliceInitialState,

  toggleTargetCurrentPlaylist(playlistId) {
    set((state) => {
      const newSelectedIds = new Set(state.selectedTargetPlaylistIds);
      if (newSelectedIds.has(playlistId)) {
        newSelectedIds.delete(playlistId);
      } else {
        newSelectedIds.add(playlistId);
      }
      return { ...state, selectedTargetPlaylistIds: newSelectedIds };
    });

    get().updateTargetCheckboxState();
  },

  removeFromSelectedTargetPlaylistIds(playlistId) {
    if (get().selectedTargetPlaylistIds.has(playlistId)) {
      set((state) => {
        const newSelectedIds = new Set(state.selectedTargetPlaylistIds);
        newSelectedIds.delete(playlistId);
        return { ...state, selectedTargetPlaylistIds: newSelectedIds };
      });
    }
  },

  updateTargetCheckboxState() {
    set((state) => ({
      ...state,
      anyTargetSelected: state.selectedTargetPlaylistIds.size > 0,
      allTargetsSelected:
        state.userEditablePlaylists?.length === state.selectedTargetPlaylistIds.size,
    }));
  },

  setAllEditablePlaylistsAndInTargetSelectionAsTarget() {
    // union of selectedTargetPlaylistIds and targetPlaylistSelection
    set((state) => ({
      ...state,
      selectedTargetPlaylistIds: new Set([
        ...state.selectedTargetPlaylistIds, // keep those already in selection there, with transferring check if they're in both
        ...state.targetPlaylistSelection,
      ]),
    }));
  },

  setAllEditablePlaylistsAsNotTarget() {
    set({ selectedTargetPlaylistIds: new Set(), clickedSelectAllTarget: false });
    get().updateTargetCheckboxState();
  },

  toggleTargetCheckbox() {
    const { anyTargetSelected, allTargetsSelected, clickedSelectAllTarget } = get();

    const noTargetSelected = !anyTargetSelected;

    if (clickedSelectAllTarget) {
      get().setAllEditablePlaylistsAsNotTarget();
    } else {
      if (anyTargetSelected || noTargetSelected) {
        get().setAllEditablePlaylistsAndInTargetSelectionAsTarget();
      }

      if (allTargetsSelected) {
        get().setAllEditablePlaylistsAsNotTarget();
      }
    }

    // Toggle the clickedSelectAll state
    set({ clickedSelectAllTarget: !clickedSelectAllTarget });

    get().updateTargetCheckboxState();
  },

  async addTracksToSelectedTargetPlaylists(tracks: AddTrackInfo[]) {
    const { sourcePlaylist, selectedTargetPlaylistIds, targetPlaylistSelection } = get();

    if (!sourcePlaylist) {
      logError('Missing source playlist while adding tracks to target playlists');
      return;
    }

    // required ids is intersection of selectedTargetPlaylistIds and targetPlaylistSelection
    const targetPlaylistIds = new Set(
      [...selectedTargetPlaylistIds].filter((id) => targetPlaylistSelection.has(id))
    );

    if (targetPlaylistIds.size === 0) {
      get().informUser(noTargetPlaylistInfo);
      return;
    }

    const apiPromises: Promise<AxiosResponse<SpotifyPlaylist>>[] = [];

    // if the sourcePlaylist is also in the target playlists, we want to store its snapshot_id, so we store it first
    const sourcePlaylistIsInTargetPlaylists = targetPlaylistIds.has(sourcePlaylist.id);

    const axiosInstance = axiosService.getInstance();

    if (sourcePlaylistIsInTargetPlaylists && !sourcePlaylist.isLikedSongs) {
      const addToSourcePlaylistPromise = axiosInstance.post(
        `${SpotifyApiPaths.Playlists}/${sourcePlaylist.id}/tracks`,
        { uris: tracks.map((track) => track.uri) }
      );
      apiPromises.push(addToSourcePlaylistPromise);
      targetPlaylistIds.delete(sourcePlaylist.id);
    }

    // if we have a liked song, we only add it to liked songs if it's not already liked
    const likedSongsPlaylist = targetPlaylistIds.has(likedSongsId);

    if (likedSongsPlaylist) {
      try {
        const response = await axiosInstance.get<boolean[]>(
          `${SpotifyApiPaths.MyTracks}/contains?ids=${tracks.map((track) => track.id).join(',')}`
        );

        const likedSongs = response.data; // boolean[] indicating which tracks are liked

        // Filter out the tracks that are not liked
        const tracksToLike = tracks.filter((_, index) => !likedSongs[index]);

        // Extract the IDs of the tracks that need to be liked
        const idsToLike = tracksToLike.map((track) => track.id).join(',');

        if (idsToLike.length > 0) {
          const likeSongPromise = axiosInstance.put(
            `${SpotifyApiPaths.MyTracks}?ids=${idsToLike}`,
            null
          );
          apiPromises.push(likeSongPromise);
        }
      } catch (error) {
        logError('Error checking liked songs or liking songs', error as Error);
      }
    }
    targetPlaylistIds.delete(likedSongsId);

    // then we resolve all the other target playlists (so not the liked songs and not the source playlist)
    apiPromises.push(
      ...[...targetPlaylistIds].map((playlistId) =>
        axiosInstance.post(`${SpotifyApiPaths.Playlists}/${playlistId}/tracks`, {
          uris: tracks.map((track) => track.uri),
        })
      )
    );

    // resolve the promise.all
    const results = await Promise.all(apiPromises);

    // get the snapshot_id from the sourcePlaylist and update it
    if (sourcePlaylistIsInTargetPlaylists) {
      get().updateSnapshotIdOfSourcePlaylist(results[0].data.snapshot_id);
    }
  },
});
