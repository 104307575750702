import { Button, Checkbox, Group, Modal, rem, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useCombinedStore } from '../../../stores/combinedStore';
import { DialogResults } from '../../../constants/enums';
import { logError } from '../../../utils/logError';

export function ConfirmLikeOrderModal() {
  const [checked, setChecked] = useState(false);

  const likeOrderDialogIsOpen = useCombinedStore((state) => state.likeOrderModalIsOpen);
  const closeLikeOrderDialog = useCombinedStore((state) => state.closeLikeOrderModal);
  const setLikeOrderDialogDontShowAgainStatus = useCombinedStore(
    (state) => state.setLikeOrderModalDontShowAgainStatus
  );

  if (!likeOrderDialogIsOpen) {
    return null;
  }

  if (!closeLikeOrderDialog) {
    logError('Close modal callback not in state');
    return null;
  }

  const handleConfirm = () => {
    setLikeOrderDialogDontShowAgainStatus(checked);
    closeLikeOrderDialog(DialogResults.Confirmed);
  };

  const handleCancel = () => {
    closeLikeOrderDialog(DialogResults.Cancelled);
  };

  return (
    <Modal
      opened={likeOrderDialogIsOpen}
      onClose={handleCancel}
      title={
        <Text fw={700} variant="gradient" gradient={{ from: 'red', to: 'orange' }}>
          Warning: Unliking a Song
        </Text>
      }
      yOffset="18.5dvh"
      size="xl"
      radius="lg"
      padding="xl"
      styles={{
        header: { paddingBottom: rem(16) },
      }}
    >
      <Text>You are about to either</Text>
      <ol>
        <li>unlike a song, which also results in removing it from your Liked Song playlist,</li>
        <li>
          move or remove a song from your Liked Song playlist, which also results in unliking the
          song.
        </li>
      </ol>
      <Text>If you proceed:</Text>
      <ul>
        <li>The song will be removed from your Liked Songs playlist.</li>
        <li>
          You can undo this action, but the song will be added as a new liked song at the top of
          your playlist.
        </li>
        <li>The original order of your liked songs will not be preserved.</li>
      </ul>
      <Checkbox
        checked={checked}
        onChange={(event) => setChecked(event.currentTarget.checked)}
        label="Don't show this warning again"
        mb="lg"
      />
      <Group>
        <Button variant="light" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </Group>
    </Modal>
  );
}
