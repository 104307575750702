import { NotificationType } from './enums';

export const noTargetPlaylistInfo = {
  title: 'No target playlist selected',
  message:
    `No 'target' playlist was selected to transfer tracks to. Please select at least one target ` +
    `playlist. You can edit the list of target playlists in the 'Edit target playlists' tab.`,
  type: NotificationType.Info,
};

export const noTracksSelectedInfo = {
  title: 'No tracks selected',
  message: `Select at least one track to move or copy`,
  type: NotificationType.Info,
};

export function actionUpdatedInfo(sourcePlaylistName: string) {
  return {
    title: 'Action updated',
    message:
      `Since you don't have edit permissions for the playlist '${sourcePlaylistName}', your ` +
      `'transfer action' has been changed from 'move' to 'copy'. This ensures your changes are ` +
      `saved without altering the original playlist.`,
    type: NotificationType.Info,
  };
}

export const signedOutWarning = {
  title: 'Signed out',
  message: 'You were signed out, please authorize Spotify again.',
  type: NotificationType.Warning,
};
